import { useEffect, useState } from 'react';
import { Container, ListGroup, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const StoreGroupsDataPage = () => {
    const stores = useSelector(state => state.filters.stores);

    const [storeGroups, setStoreGroups] = useState([]);

    useEffect(() => {
        const storeGroups = [];
        for (let i = 0; i < stores.length; i++) {
            if (stores[i].marketplace === 'boom') {
                const groupIndex = storeGroups.findIndex(g => g.group === stores[i].group);
                if (groupIndex === -1) {
                    storeGroups.push({group: stores[i].group, stores: [stores[i]]});
                } else {
                    storeGroups[groupIndex].stores.push(stores[i]);
                }
            }
        }

        setStoreGroups(storeGroups);
    }, [stores]);

    return (
        <Container className="store-groups-data-page">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Group</th>
                        <th>Stores</th>
                    </tr>
                </thead>
                <tbody>
                    {storeGroups.map((storeGroup, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{storeGroup.group}</td>
                            <td>
                                <ListGroup>
                                    {storeGroup.stores.map(store => (
                                        <ListGroup.Item key={store.id}>
                                            {store.id} ({store.boomSubjects}, {store.boomClass})
                                        </ListGroup.Item>
                                    ))}

                                </ListGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default StoreGroupsDataPage;