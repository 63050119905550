import { Spinner } from 'react-bootstrap';

const LoadingComponent = ({loading}) => {
    if (!loading) {
        return null;
    }

    return (
        <div className="loading-container">
            <Spinner animation="border" variant="light" />
        </div>
    )
}

export default LoadingComponent;