import { useSelector } from 'react-redux';
import Select from 'react-select';

const FilterByTheme = ({ filteringTheme, setFilteringTheme, valueField = 'id' }) => {
    const themes = useSelector(state => state.filters.themes);

    if (themes === undefined) {
        return null;
    }

    // Group themes by their parent
    const groupThemes = (themes) => {
        const grouped = {};

        // First pass: identify parent themes
        themes.forEach(theme => {
            if (!theme.parent) {
                grouped[theme.id] = {
                    label: theme.name,
                    options: [{
                        value: theme[valueField],
                        label: theme.name
                    }]
                };
            }
        });

        // Second pass: group children themes
        themes.forEach(theme => {
            if (theme.parent) {
                const parentGroup = grouped[theme.parent];
                if (parentGroup) {
                    parentGroup.options.push({
                        value: theme[valueField],
                        label: theme.name
                    });
                }
            }
        });

        return Object.values(grouped);
    }

    const groupedOptions = groupThemes(themes);

    const handleThemeChange = (selectedOption) => {
        setFilteringTheme(selectedOption ? selectedOption.value : 'all');
    }

    const getCurrentValue = () => {
        if (!filteringTheme) return 'all';

        // Search through all options to find the matching value
        for (const group of groupedOptions) {
            const found = group.options.find(opt => opt.value === filteringTheme);
            if (found) return found;
        }
        return 'all';
    };

    return (
        <Select
            options={groupedOptions}
            onChange={handleThemeChange}
            isClearable
            placeholder="Select a theme..."
            className="theme-select"
            value={getCurrentValue()}
        />
    );
};

export default FilterByTheme;
