import { Button, Modal } from 'react-bootstrap';
import TemplatesManagement from '../../../../auto-content/templates-management';
import { useState } from 'react';

const DataSetDesignRowSelectTemplateModal = ({ insertDesignRows, hideModal }) => {
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    return (
        <Modal size="xl"
            show={true}
            onHide={hideModal}
            className="-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>New Design Row</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplatesManagement
                    selectedTemplates={selectedTemplates}
                    setSelectedTemplates={setSelectedTemplates}
                    selectedCollections={[]}
                    setSelectedCollections={() => {}}
                    usedTemplateIDs={[]}
                    insertTemplates={() => {}}
                    displayMode="insert"
                />
            </Modal.Body>
            <Modal.Footer>
                {selectedTemplates.length > 0 && (
                    <Button onClick={() => insertDesignRows(selectedTemplates)}>Insert</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}
export default DataSetDesignRowSelectTemplateModal;