import { faArrowDown, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Table } from 'react-bootstrap';
import './styles.scss';
import { useState } from 'react';

const GenDataTicketIdeaLayout = ({ticket, layout, updateLayout, disabled, dataSets}) => {
    const [selectedDataSet, setSelectedDataSet] = useState(null);

    const updatePart = (partIndex, field, value) => {
        updateLayout(layout.map((part, index) => {
            if (index === partIndex) {
                return {...part, [field]: value};
            }

            return part;
        }));
    }

    const deleteDataSet = async(dataSetIndex) => {
        updateLayout(layout.filter((ds, index) => index !== dataSetIndex));
    }

    const addDataSetToLayout = async() => {
        if (selectedDataSet) {
            const dataSet = dataSets.find(ds => ds.id == selectedDataSet);
            const data = {
                id: dataSet.id,
                activity: dataSet.activity,
                dataType: dataSet.dataType,
                quality: dataSet.quality,
                template: dataSet.template,
                collection: dataSet.collection,
                nbPages: dataSet.nbQuestionsValidated,
                skill: null,
                cbc: false,
                mystery: false,
            };
            if (layout) {
                updateLayout(layout.concat(data));
            } else {
                updateLayout([data]);
            }
            setSelectedDataSet(null);
        }
    }

    const moveItem = (array, index, direction) => {
        if (direction === 'up' && index === 0) {
            return array;
        }
        if (direction === 'down' && index === array.length - 1) {
            return array;
        }

        const newArray = [...array];
        const itemToMove = newArray[index];

        if (direction === 'up') {
            newArray[index] = newArray[index - 1];
            newArray[index - 1] = itemToMove;
        } else if (direction === 'down') {
            newArray[index] = newArray[index + 1];
            newArray[index + 1] = itemToMove;
        }

        return newArray;
    }

    const moveDataSet = (partIndex, dir) => {
        updateLayout(moveItem(layout, partIndex, dir));
    }

    return (
        <div className="gen-data-ticket-idea-layout">
            {layout && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Data Set</th>
                            <th>Nb Pages</th>
                            <th>Skill</th>
                            <th>CBC</th>
                            <th>Mystery</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {layout.map((part, partIndex) => (
                            <tr key={partIndex}>
                                <td>
                                    <a href={'/data-set/?id=' + part.id} target="_blank">
                                        {part.id}. {part.activity} ({part.dataType} - {part.quality})
                                    </a>
                                </td>
                                <td>
                                    <Form.Control
                                        value={part.nbPages}
                                        onChange={e => updatePart(partIndex, 'nbPages', e.target.value)}
                                        type="number"
                                        disabled={disabled} />
                                </td>
                                <td>
                                    <Form.Control
                                        value={part.skill}
                                        onChange={e => updatePart(partIndex, 'skill', e.target.value)}
                                        disabled={disabled} />
                                </td>
                                <td>
                                    <Form.Check
                                        id={'part-' + partIndex + '-cbc-checkbox'}
                                        type="checkbox"
                                        value={part.cbc}
                                        onChange={() => updatePart(partIndex, 'cbc', !part.cbc)}
                                        checked={part.cbc}
                                        disabled={disabled}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        id={'part-' + partIndex + '-mystery-checkbox'}
                                        type="checkbox"
                                        value={part.mystery}
                                        onChange={() => updatePart(partIndex, 'mystery', !part.mystery)}
                                        checked={part.mystery}
                                        disabled={disabled}
                                    />
                                </td>
                                <td className="btns">
                                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteDataSet(partIndex)} />
                                    <FontAwesomeIcon icon={faArrowUp} onClick={() => moveDataSet(partIndex, 'up')} />
                                    <FontAwesomeIcon icon={faArrowDown} onClick={() => moveDataSet(partIndex, 'down')} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            {!disabled && (
                <Form className="add-dataset-form">
                    <Form.Select value={selectedDataSet} onChange={e => setSelectedDataSet(e.target.value)}>
                        <option></option>
                        {dataSets.filter(dataSet => dataSet.store === ticket.store).map(dataSet => (
                            <option key={dataSet.id} value={dataSet.id}>
                                {dataSet.id}. {dataSet.activity} ({dataSet.dataType} - {dataSet.quality})
                            </option>
                        ))}
                    </Form.Select>
                    <Button size="sm" onClick={addDataSetToLayout}>Add</Button>
                </Form>
            )}
        </div>
    );
}

export default GenDataTicketIdeaLayout;