import { Button, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import './styles.scss';
import { updateDataAPI } from '../../../../utils/query';

const DataSetImagesEditorModal = ({dataSet, defaultActiveImageIndex, hideModal, handleSaveDataSetChanges}) => {
    const [activeImageIndex, setActiveImageIndex] = useState(defaultActiveImageIndex);
    const [activeImage, setActiveImage] = useState(null);

    const [cropZoneTop, setCropZoneTop] = useState(null);
    const [cropZoneLeft, setCropZoneLeft] = useState(null);
    const [cropZoneBottom, setCropZoneBottom] = useState(null);
    const [cropZoneRight, setCropZoneRight] = useState(null);
    const [cropOriginalImgWidth, setCropOriginalImgWidth] = useState(null);
    const [cropOriginalImgHeight, setCropOriginalImgHeight] = useState(null);

    const [selectedImageIndexes, setSelectedImageIndexes] = useState([defaultActiveImageIndex]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setActiveImage(dataSet.topics[activeImageIndex]['icon']);
    }, [activeImageIndex]);

    const handleClick = (event) => {
        const rect = event.target.getBoundingClientRect();

        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (event.shiftKey) {
            setCropZoneTop(y);
            setCropZoneLeft(x);
            setCropOriginalImgWidth(parseInt(rect['width']));
            setCropOriginalImgHeight(parseInt(rect['height']));
        }
        if (event.ctrlKey || event.metaKey) {
            setCropZoneBottom(y);
            setCropZoneRight(x);
            setCropOriginalImgWidth(parseInt(rect['width']));
            setCropOriginalImgHeight(parseInt(rect['height']));
        }
    }

    const cropAndConfirmImages = async() => {
        if (selectedImageIndexes.length === 0) {
            return;
        }

        if (window.confirm('Are you sure?')) {
            const data = {
                cropZoneTop,
                cropZoneLeft,
                cropZoneBottom,
                cropZoneRight,
                cropOriginalImgWidth,
                cropOriginalImgHeight,
            };
            await cropImages(data);

            setCropZoneTop(null);
            setCropZoneLeft(null);
            setCropZoneBottom(null);
            setCropZoneRight(null);
            setCropOriginalImgWidth(null);
            setCropOriginalImgHeight(null);
            setActiveImage(activeImage);

            window.alert('crop successfully');
        }
    }

    const cropImages = async(data) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/crop-images-in-dataset/', {
            ...data,
            dataSetID: dataSet.id,
            rowIndexes: selectedImageIndexes,
        });
        const dataSetData = {
            ...dataSet,
            topics: res.data['topics'],
        };
        await handleSaveDataSetChanges(dataSetData);
        setLoading(false);
        setSelectedImageIndexes([]);
    }

    const convertIconToText = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/extract-text-from-images-in-dataset/', {
            dataSetID: dataSet.id,
            rowIndexes: selectedImageIndexes,
        });
        const dataSetData = {
            ...dataSet,
            topics: res.data['topics'],
        };
        await handleSaveDataSetChanges(dataSetData);
        setLoading(false);
        setSelectedImageIndexes([]);
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="dataset-images-editor-modal"
            fullscreen={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    DataSet Images Editor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="images-editor">
                    <div>
                        {activeImage && (
                            <div className="active-image-container">
                                <img src={activeImage} onClick={handleClick} />

                                {cropZoneLeft && cropZoneTop && cropZoneRight && cropZoneBottom && (
                                    <div className="crop-zone"
                                        style={{'top': cropZoneTop + 'px', 'left': cropZoneLeft + 'px', 'width': parseInt(cropZoneRight - cropZoneLeft) + 'px', 'height': parseInt(cropZoneBottom - cropZoneTop) + 'px'}}>
                                    </div>
                                )}
                            </div>
                        )}

                        {activeImage && (
                            <div className="crop-zone-info">
                                <div>
                                    <pre>{JSON.stringify(dataSet.topics[activeImageIndex], null, 4)}</pre>
                                </div>
                                <div>
                                    <div>Hold <strong>shift</strong> then click to register crop zone top left</div>
                                    <div>Hold <strong>ctrl</strong> or <strong>command</strong> then click to register crop zone bottom right</div>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Top Left Corner: x: {cropZoneLeft}px - y: {cropZoneTop}px
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Bottom Right Corner: x: {cropZoneRight}px - y: {cropZoneBottom}px
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Image: w: {cropOriginalImgWidth}px - y: {cropOriginalImgHeight}px
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <div className="mt-3">
                                        {cropZoneLeft && cropZoneTop && cropZoneRight && cropZoneBottom && (
                                            <Button variant="info" onClick={cropAndConfirmImages}>Crop Selected Images</Button>
                                        )}

                                        <Button onClick={convertIconToText}>Convert Icon to Text</Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="all-images">
                            {dataSet.topics.map((row, rowIndex) => (
                                <div key={rowIndex} onClick={e => {
                                    if (selectedImageIndexes.indexOf(rowIndex) > -1) {
                                        setSelectedImageIndexes(selectedImageIndexes.filter(index => index !== rowIndex));
                                    } else {
                                        if (e.shiftKey) {
                                            let nearestSmallerIndex = 0;
                                            for (let i = 0; i < dataSet.topics.length; i++) {
                                                if (selectedImageIndexes.indexOf(i) > -1 && i < rowIndex && i > nearestSmallerIndex) {
                                                    nearestSmallerIndex = i;
                                                }
                                            }
                                            const toAddIndexes = [];
                                            for (let i = nearestSmallerIndex; i <= rowIndex; i++) {
                                                toAddIndexes.push(i);
                                            }
                                            setSelectedImageIndexes(selectedImageIndexes.concat(toAddIndexes));
                                        } else {
                                            setSelectedImageIndexes(selectedImageIndexes.concat(rowIndex));
                                        }
                                    }
                                }} className={'image-container ' + (selectedImageIndexes.indexOf(rowIndex) > -1 ? 'selected' : '')}>
                                    {row['icon'] && (
                                        <img src={row['icon']} onClick={() => setActiveImageIndex(rowIndex)} />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default DataSetImagesEditorModal;