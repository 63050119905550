import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import ReactQuill from 'react-quill';
import { useEffect, useState } from 'react';

const IdeaModalTitleDescriptionTab = ({
    customID,
    store,
    title,
    setTitle,
    titleReachMaxLength,
    setTitleReachMaxLength,
    titleFreeReachMaxLength,
    setTitleFreeReachMaxLength,
    titleWarning,
    titleFree,
    setTitleFree,
    titleFreeWarning,
    dollarDealTitle,
    setDollarDealTitle,
    freeSkills,
    description,
    setDescription,
    descriptionFree,
    setDescriptionFree,
}) => {
    const [titleMaxLength, setTitleMaxLength] = useState(80);

    useEffect(() => {
        fetchTitleMaxLength();
    }, [store]);

    const fetchTitleMaxLength = async() => {
        const res = await getDataFromAPI('stores/' + store + '/');
        if (res.data['marketplace'] === 'boom') {
            setTitleMaxLength(60);
        } else {
            setTitleMaxLength(80);
        }
    }

    const handleChangeTitle = async (e) => {
        const { value } = e.target;

        if (value.length <= titleMaxLength) {
            setTitle(value);
        }
        setTitleReachMaxLength(value.length >= titleMaxLength);
    }

    const autoGenerateDescription = async() => {
        try {
            const res = await updateDataAPI('POST', 'resources/generate-auto-idea-description/?customID=' + customID);
            setDescription(res.data['description']);
            setDescriptionFree(res.data['descriptionFree']);
        } catch (err) {
            window.alert('Can not auto fill description. Check store template or other info using in desc like title, subjects, grades then try again');
            return;
        }
    }

    return (
        <div>
            <Row className="mt-3">
                <Col>
                    <Form.Group className="mb-3 field-with-event">
                        <Form.Label>
                            Title PAID (max {titleMaxLength} characters)
                            {/* <FontAwesomeIcon icon={faCalendar} className="show-product-idea-event-icon" onClick={() => setEdittingFieldByEvent('title')} /> */}
                        </Form.Label>
                        <Form.Control as="textarea" rows={2} value={title} maxLength={titleMaxLength} onChange={(e) => handleChangeTitle(e)} />
                        <Form.Text>{title ? title.length : 0} characters</Form.Text>
                        {titleReachMaxLength && (
                            <Alert variant="warning" className="mt-3">Title reaches {titleMaxLength} characters</Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3 field-with-event">
                        <Form.Label>
                            DOLLAR DEAL TITLE (max {titleMaxLength} characters)
                        </Form.Label>
                        <Form.Control as="textarea" rows={2} value={dollarDealTitle} maxLength={titleMaxLength} onChange={(e) => setDollarDealTitle(e.target.value)} />
                        <Form.Text>{dollarDealTitle ? dollarDealTitle.length : 0} characters</Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3 field-with-event">
                        <Form.Label>
                            Title FREE (max {titleMaxLength} characters)
                        </Form.Label>
                        <Alert variant="warning">
                            <div><u>Remove the number of pages</u> of the PAID product in the Free Product</div>
                        </Alert>
                        {freeSkills.length > 0 && (
                            <Alert variant="warning">
                                <div>Free Skills: {freeSkills.map(skill => skill.name).join(', ')}</div>
                            </Alert>
                        )}
                        <Form.Control as="textarea" rows={2} value={titleFree} maxLength={titleMaxLength} onChange={e => {
                            if (e.target.value.length <= titleMaxLength) {
                                setTitleFree(e.target.value);
                            }
                            setTitleFreeReachMaxLength(e.target.value.length >= titleMaxLength);
                        }} />
                        <Form.Text>{titleFree ? titleFree.length : 0} characters</Form.Text>
                        {titleFreeReachMaxLength && (
                            <Alert variant="warning" className="mt-3">Title reaches {titleMaxLength} characters</Alert>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <div className="description-header">
                            <Form.Label>Description PAID</Form.Label>
                            <Button variant="link" onClick={autoGenerateDescription}>Auto Generate</Button>
                        </div>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description FREE</Form.Label>
                        <Alert variant="danger">
                            KHÔNG ghi các thông tin mà sản phẩm free không có trong Description của sản phẩm free. <br />
                            Tip: copy desc của sản phẩm paid ở trên, bỏ hết các thông tin về <u>số trang</u> và các <u>thông tin chi tiết có thể bị bắt bẻ</u> về các hoạt động như what's included, tránh bị user report khi viết activity trong desc free mà sp free lại ko có activity đó</Alert>
                        <ReactQuill theme="snow" value={descriptionFree} onChange={setDescriptionFree} />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default IdeaModalTitleDescriptionTab;