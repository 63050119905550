import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenFancy,
    faArrowCircleRight,
    faArrowCircleLeft,
    faListCheck,
    faRobot,
    faBug,
} from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { Form, ProgressBar } from 'react-bootstrap';
import Constant from '../../../Constant';
import { useSelector } from 'react-redux';
import IdeaModal from '../../../pages/idea-list/idea-modal';
import TaskCheckListModal from '../task-checklist-modal';
import ManualTaskModal from '../manual-task-modal';
import { hasPermission } from '../../../utils/auth';
import TaskDebugModal from '../task-debug-modal';
import Moment from 'react-moment';
import TaskPreviewBoxTags from './task-preview-box-tags';
import ImageWrapper from '../../common/image-wrapper';
import RefreshTaskProgressButton from '../../common/flow-progress/refresh-task-progress-button';

const TaskPreviewBox = ({
    displayMode,
    task,
    tasks,
    setLoading,
    setTasks,
    ADDITION_FIELDS,
    step,
    taskFlowSteps,
    fetchTask,
    fetchTasks,
    setNotification }) => {
    const selectedTaskFlow = useSelector((state) => state.filters.selectedTaskFlow);
    const { routePermissions, activityPermissions } = useSelector((state) => state.permissions);

    const [edittingProductIdea, setEdittingProductIdea] = useState(null);
    const [edittingTask, setEdittingTask] = useState(null);
    const [taskInCheckListModal, setTaskInCheckListModal] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [showTaskDebugModal, setShowTaskDebugModal] = useState(false);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const showIdeaModal = async (ideaID) => {
        const res = await getDataFromAPI('product-ideas/' + ideaID + '/?additionalFields=highlightImages');
        setEdittingProductIdea(res.data);
    }

    const updateTaskField = async (field, val) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'product-dev/tasks/' + task.id + '/?' + ADDITION_FIELDS, {
            [field]: val
        });
        setTimeout(async () => {
            const res = await getDataFromAPI('product-dev/tasks/' + task.id + '/?' + ADDITION_FIELDS);
            setTasks(tasks.map(t => {
                if (t.id === task.id) {
                    return res.data;
                }
                return t;
            }));
            setLoading(false);
        }, 1000);
    }

    const moveTaskToColumn = async (dir) => {
        const currentStepIndex = taskFlowSteps.findIndex(s => step.id === s.id);
        if (dir === 'next') {
            await updateTaskField('step', taskFlowSteps[currentStepIndex + 1].id);
        } else {
            await updateTaskField('step', taskFlowSteps[currentStepIndex - 1].id);
        }
    }

    const refreshTaskProgress = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'product-dev/force-update-task-progress/?taskID=' + task.id);
        await fetchTask(task.id);
        setLoading(false);
    }

    return (
        <>
            <div className="task-preview-box">
                <TaskPreviewBoxTags task={task} selectedTaskFlow={selectedTaskFlow} />
                {task.thumbnail && (
                    <ImageWrapper src={task.thumbnail} className="task-thumbnail" />
                )}
                <div className="task-title" onClick={() => {
                    if (selectedTaskFlow === Constant.MANUAL_CATEGORY_ID) {
                        setEdittingTask(task);
                    } else {
                        showIdeaModal(task.productIdea);
                    }
                }}>{task.ideaName ? `${task.customID}. ${task.ideaName}` : task.title}</div>
                <hr />
                {selectedTaskFlow !== Constant.MANUAL_CATEGORY_ID && (
                    <div className="store">
                        Store {task.store}
                    </div>
                )}
                {task.assignedStaffs.length > 0 ? (
                    <div className="assignees">
                        {task.assignedStaffs.map(staff => (
                            <div key={staff.id}>{staff.role ? staff.role.toUpperCase() : ''}: {staff.name}</div>
                        ))}
                    </div>
                ) : (
                    <div className="assignees text-danger">
                        Missing Assignees
                    </div>
                )}
                {task.usedHours && (
                    <div className="task-used-hours">Spent: {task.usedHours} hours</div>
                )}
                <div className="task-created-at">Created at: <Moment format="DD/MM/YYYY">{task.createdAt}</Moment></div>
                <hr />
                <div className="task-footer">
                    {task.genDataTicket && step.createGenDataTicketWhenCreate && (
                        <div className="ticket-progress">
                            <span>Data:</span>
                            <ProgressBar
                                striped animated
                                variant={task.genDataTicketData.done ? 'success' : 'warning'}
                                now={task.genDataTicketData.done ? 100 : Math.floor(((task.genDataTicketData.nbFinishedParts ? task.genDataTicketData.nbFinishedParts : 0) / task.genDataTicketData.totalParts) * 100)} />
                        </div>
                    )}
                    {task.exportTicket && step.createGenDataTicketWhenCreate && (
                        <div className="ticket-progress">
                            <span>Export:</span>
                            <ProgressBar
                                striped animated
                                variant={task.exportTicketData.done ? 'success' : 'warning'}
                                now={task.exportTicketData.done ? 100 : Math.floor(((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages) * 100)} />
                        </div>
                    )}
                    {task.designTicket && step.createDesignTicketWhenCreate && (
                        <div className="ticket-progress">
                            <span>Render:</span>
                            <ProgressBar
                                striped animated
                                variant={task.designTicketData.done ? 'success' : 'warning'}
                                now={task.designTicketData.done ? 100 : Math.floor(((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages) * 100)} />
                        </div>
                    )}
                    {task.nbPageStatues && task.nbPageStatues['totalAutoPages'] > 0 && (
                        <div className="ticket-progress">
                            <span className={task.nbPageStatues['nbAutoPagesHasData'] < task.nbPageStatues['totalAutoPages'] ? 'text-danger' : 'text-success'}>
                                Auto: {task.nbPageStatues['nbAutoPagesHasData']} / {task.nbPageStatues['totalAutoPages']} pages ({Math.floor(task.nbPageStatues['nbAutoPagesHasData'] / task.nbPageStatues['totalAutoPages'] *100)}%)
                            </span>
                        </div>
                    )}
                    {task.nbPageStatues && task.nbPageStatues['totalManualPages'] > 0 && (
                        <div className="ticket-progress">
                            <span>Manual: {task.nbPageStatues['nbManualPagesHasData']} / {task.nbPageStatues['totalManualPages']} pages</span>
                        </div>
                    )}
                    <div className="task-footer-row">
                        {step.pos == 2 && selectedTaskFlow !== Constant.MANUAL_CATEGORY_ID && hasPermission(userRoles, activityPermissions['CHECK_LAYOUT_DONE']) && (
                            <Form>
                                <Form.Check className="layout-done-checkbox" type="checkbox" label="Layout Done" checked={task.layoutDone} id={'task-' + task.id + '-layout-done-checkbox'}
                                    onChange={e => updateTaskField('layoutDone', !task.layoutDone)} />
                            </Form>
                        )}
                    </div>
                    <div className="task-footer-row">
                        <div>
                            {displayMode === 'standard' && (
                                <FontAwesomeIcon icon={faListCheck} onClick={() => setTaskInCheckListModal(task)} />
                            )}
                        </div>
                        <div className="meta-links">
                            {hasPermission(userRoles, ['admin', 'dev']) && (
                                <span className="tool-icon" onClick={() => setShowTaskDebugModal(true)}>
                                    <FontAwesomeIcon icon={faBug} />
                                </span>
                            )}
                            {hasPermission(userRoles, routePermissions['/content-studio'])
                                && selectedTaskFlow === Constant.WORKSHEET_CATEGORY_ID && (
                                <a title="ai studio" href={'/content-studio?customID=' + task.customID} target="_blank" className="tool-icon">
                                    <FontAwesomeIcon icon={faRobot} />
                                </a>
                            )}
                            {hasPermission(userRoles, routePermissions['/colorbycode-maker'])
                                && selectedTaskFlow === Constant.COLORBYCODE_CATEGORY_ID && (
                                    <a title='generate product color by code' href={"/colorbycode-maker?customID=" + task.customID} target="_blank" className="tool-icon">
                                        <FontAwesomeIcon icon={faPenFancy} />
                                    </a>
                                )}
                            <RefreshTaskProgressButton
                                task={task}
                                handleRefreshSteps={() => fetchTask(task?.id)}
                            />
                            {/* {task.discordChannelID && (
                                <span onClick={() => {
                                    setActiveChatChannelID(null);
                                    setTimeout(() => {
                                        setActiveChatChannelID(task.discordChannelID);
                                    }, 500);
                                }} title="chat" className="tool-icon">
                                    <FontAwesomeIcon icon={faComment} />
                                </span>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>

            {selectedTaskFlow === Constant.MANUAL_CATEGORY_ID && step.pos < taskFlowSteps.length && (
                <span className="move-task-to-next-column" title="Move task to next column" onClick={() => moveTaskToColumn('next')}>
                    <FontAwesomeIcon icon={faArrowCircleRight} />
                </span>
            )}
            {selectedTaskFlow === Constant.MANUAL_CATEGORY_ID && step.pos > 1 && (
                <span className="move-task-to-prev-column" title="Move task to previous column" onClick={() => moveTaskToColumn('prev')}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} />
                </span>
            )}
            {edittingProductIdea !== null && (
                <IdeaModal
                    idea={edittingProductIdea}
                    closeModal={action => {
                        if (action === 'update') {
                            fetchTask(task.id);
                            setNotification('Update successfully');
                        } else if (action === 'delete') {
                            fetchTask(task.id);
                            setNotification('Delete successfully');
                        }
                        setEdittingProductIdea(null);
                    }}
                />
            )}
            {edittingTask !== null && (
                <ManualTaskModal task={task} closeModal={action => {
                    if (action === 'update') {
                        fetchTasks();
                        setNotification('Update successfully');
                    } else if (action === 'delete') {
                        fetchTasks();
                        setNotification('Delete successfully');
                    }
                    setEdittingTask(null);
                }} />
            )}

            {taskInCheckListModal !== null && (
                <TaskCheckListModal
                    task={taskInCheckListModal}
                    taskFlowSteps={taskFlowSteps}
                    closeModal={() => setTaskInCheckListModal(null)} />
            )}

            {showTaskDebugModal && (
                <TaskDebugModal
                    task={task}
                    hideModal={() => {
                        setShowTaskDebugModal(false);
                        refreshTaskProgress(task);
                    }}
                />
            )}
        </>
    );
}

export default TaskPreviewBox;