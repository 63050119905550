import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import InsertDataSetModal from './insert-data-set-modal';
import DataSetData from './data-set-data';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { Alert, ButtonGroup, Dropdown, ListGroup, Table } from 'react-bootstrap';
import DataSetModal from './data-set-modal';
import Constant from '../../../Constant';
import DataSetIconsManagementModal from './data-set-icons-management-modal';
import KeywordInfoModal from '../../product-map/keyword-info-modal';
import DataSetDesignRows from './dataset-design-rows';
import UpdateJSONDataSetModal from './update-json-data-set-modal';

const DataSetRow = ({
    dataSet,
    showContent=false,
    setDataSet,
    handleDeleteDataSet,
    handleDuplicateDataSet,
    setSelectedDataSet,
    setLoading,
}) => {
    const [showInsertDataSetModal, setShowInsertDataSetModal] = useState(false);
    const [showUpdateJSONDataSetModal, setShowUpdateJSONDataSetModal] = useState(false);
    const [showDataSetModal, setShowDataSetModal] = useState(false);
    const [showDataSetIconsManagementModal, setShowDataSetIconsManagementModal] = useState(false);
    const [insertDataType, setInsertDataType] = useState('TABLE');
    const [keywordInInfoModal, setKeywordInInfoModal] = useState(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (showContent) {
            fetchAllRows();
        }
    }, []);

    const fetchAllRows = async() => {
        const res = await getDataFromAPI('resources/data-set-rows/?dataSetID=' + dataSet.id);
        setRows(res.data);
    }

    const handleInsertData = async (data = '', insertUrls, quizizzKeyword, quizizzGrades, nbPages) => {
        setLoading(true);
        // if (insertDataType === 'sheet') {
        //     const newRows = data.split('\n').map((row) => row.split('\t').map((item) => item.trim()));
        //     const updatedDataSetItems = [...new Set([...(dataSet?.topics ?? []), ...newRows])];
        //     await handleSaveDataSetChanges({ ...selectedDataSet, topics: updatedDataSetItems });
        if ((insertDataType === 'quizizz_links' || insertDataType === 'ace_links' || insertDataType === 'kahoot_links') && dataSet.dataType) {
            const urls = insertUrls.split('\n');
            let nbAddedQuestions = 0;
            let nbLinksError = 0;
            for (let i = 0; i < urls.length; i++) {
                let url;
                if (insertDataType === 'quizizz_links') {
                    url = 'resources/insert-data-to-data-set-by-quiz-link/';
                } else if (insertDataType === 'ace_links') {
                    url = 'resources/insert-data-to-data-set-by-ace-link/';
                } else {
                    url = 'resources/insert-data-to-data-set-by-kahoot-link/';
                }
                try {
                    const res = await updateDataAPI('POST', url, {
                        url: urls[i].trim(),
                        dataType: dataSet.dataType,
                        dataSetID: dataSet.id,
                    });
                    nbAddedQuestions += res.data['nbAddedQuestions'];
                    fetchAllRows();
                } catch (err) {
                    nbLinksError++;
                }
            }
            window.alert(nbAddedQuestions + ' questions added. ' + nbLinksError + ' links error.');
        // } else if (insertDataType === 'quizizz keyword' && dataSet.dataType) {
        //     try {
        //         const res = await updateDataAPI('POST', 'resources/insert-data-to-data-set-by-quizizz-keyword/', {
        //             keyword: quizizzKeyword,
        //             grades: quizizzGrades,
        //             dataSetID: dataSet.id,
        //             nbPages: nbPages,
        //         });
        //         window.alert(res.data['nbAddedQuestions'] + ' questions added');
        //     } catch (err) {
        //         window.alert('An error occurred while adding questions');
        //     }
        } else if (insertDataType === 'JSON') {
            if (dataSet.dataType === 'JSON') {
                const jsonObj = JSON.parse(data);
                const res = await updateDataAPI('POST', 'resources/insert-json-data-to-dataset/', {
                    data: Array.isArray(jsonObj) ? jsonObj : [jsonObj],
                    dataSetID: dataSet.id,
                });
                setRows(res.data);
            } else {
                const jsonObj = JSON.parse(data);
                const res = await updateDataAPI('POST', 'resources/import-new-design-rows-by-json/', {
                    content: Array.isArray(jsonObj['content']) ? jsonObj['content'] : [jsonObj['content']],
                    dataSetID: dataSet.id,
                    templateID: jsonObj['template'],
                });
                window.location.reload();
            }
        }
        // } else if (insertDataType === 'tinytap') {
        //     const tinyTapID = data.trim();
        //     await updateDataAPI('POST', 'resources/insert-data-to-data-set-by-tinytap/', {tinyTapID, dataSetID: selectedDataSet.id});
        //     window.location.reload();
        // }
        setLoading(false);
        setShowInsertDataSetModal(false);
    }

    const handleSaveAndValidateDataSet = async () => {
        if (dataSet.keywords.length === 0) {
            window.alert('Can not validate data set. Make sure that you\'ve linked at least one keyword');
            return;
        }

        if (dataSet.dataType === 'JSON' && dataSet.activity === null && dataSet.template === null && dataSet.collection === null) {
            window.alert('Can not validate, this set must has at least activity, template or collection');
            return;
        }

        if (dataSet.activity === 'MCQ' && dataSet.audioAvailable !== 'yes' && dataSet.audioAvailable !== 'no') {
            window.alert('Can not validate, audio is unknown');
            return;
        }

        if (dataSet.store === null) {
            window.alert('Can not validate, store is unknown');
            return;
        }

        setLoading(true);

        if (dataSet.dataType !== 'DESIGN') {
            const resCheckDataSetMatchesTemplates = await updateDataAPI('POST', 'resources/check-dataset-valid/', {id: dataSet.id});
            if (resCheckDataSetMatchesTemplates.data['valid']) {
                const nbQuestionsValidated = window.prompt('Number of questions to validate');

                if (nbQuestionsValidated) {
                    const updatedDataSetData = {
                        validated: true,
                        nbQuestionsValidated: parseInt(nbQuestionsValidated),
                    };

                    await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', updatedDataSetData);
                    setDataSet({...dataSet, ...updatedDataSetData});
                }
            } else {
                if (resCheckDataSetMatchesTemplates.data['reason']) {
                    window.alert('Error: ' + resCheckDataSetMatchesTemplates.data['reason']);
                } else {
                    window.alert('Error');
                }
            }
        } else {
            const nbQuestionsValidated = window.prompt('Number of questions to validate');

            if (nbQuestionsValidated) {
                const updatedDataSetData = {
                    validated: true,
                    nbQuestionsValidated: parseInt(nbQuestionsValidated),
                };

                await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', updatedDataSetData);
                setDataSet({...dataSet, ...updatedDataSetData});
            }
        }
        setLoading(false);
    }

    const updateDataSet = async(keywords, dataType, activity, dataSource, template, collection, mapping, quality, seasons, audioAvailable, followedDataSet, storyName, randomOrder, store) => {
        setLoading(true);
        const updatedDataSetData = {
            dataType,
            activity,
            dataSource,
            keywords,
            template,
            collection,
            mapping,
            quality,
            seasons,
            audioAvailable,
            followedDataSet,
            storyName,
            randomOrder,
            store,
        };

        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', updatedDataSetData);
        setDataSet({...dataSet, ...updatedDataSetData});

        setLoading(false);
    }

    const skipDataSet = async () => {
        setLoading(true);

        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {skipped: true});
        setDataSet({...dataSet, skipped: true});

        setLoading(false);
    }

    // const updateJSONDataSetData = async(data) => {
    //     const jsonObj = JSON.parse(data);
    //     await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
    //         topics: jsonObj,
    //     });
    //     await handleSaveDataSetChanges({ ...dataSet, topics: jsonObj });
    //     setShowUpdateJSONDataSetModal(false);
    // }

    const updateIcons = async(icons) => {
    //     const rows = [...dataSet.topics];
    //     Object.keys(icons).forEach(rowIndex => {
    //         if (rowIndex <= rows.length) {
    //             rows[rowIndex - 1]['icon'] = icons[rowIndex];
    //         }
    //     });
    //     await handleSaveDataSetChanges({ ...dataSet, topics: rows });
    //     setShowDataSetIconsManagementModal(false);
    }

    const updateMultiIconsPerRow = async(rowIndex, icons) => {
    //     const rows = [...dataSet.topics];
    //     for (let i = 0; i < icons.length; i++) {
    //         rows[rowIndex]['icon' + (i + 1)] = icons[i];
    //     }
    //     await handleSaveDataSetChanges({ ...dataSet, topics: rows });
    //     setShowDataSetIconsManagementModal(false);
    }

    const genAudio = async() => {
        setLoading(true);
        try {
            const clear = window.confirm('Do you want to clear all existing audios?');
            let res;
            if (dataSet.dataType === 'DESIGN') {
                if (dataSet.activity === 'READING_COMPREHENSION_UNKNOWN_STORY') {
                    await updateDataAPI('POST', 'resources/gen-audio-for-reading-comprehension-dataset/', {
                        dataSetID: dataSet.id,
                        clear: true,
                    });
                } else if (dataSet.activity === 'BOOK_COMPANION_STORY_GRAMMAR' || dataSet.activity === 'BOOK_COMPANION_GRAMMAR') {
                    await updateDataAPI('POST', 'resources/gen-audio-for-book-companion-dataset/', {
                        dataSetID: dataSet.id,
                        clear: true,
                    });
                }
            } else {
                res = await updateDataAPI('POST', 'resources/gen-audio-for-dataset/', {
                    dataSetID: dataSet.id,
                    clear
                });
                if (res.data['errors'].length > 0) {
                    window.alert('Rows ' + res.data['errors'].map(err => err['rowIndex']).join(', ') + ' errors');
                    return;
                }
                await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {audioAvailable: 'yes'});
                setDataSet({...dataSet, audioAvailable: 'yes'});
            }
            window.location.reload();
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    const clearAllAudio = async() => {
        setLoading(true);
        try {
            await updateDataAPI('POST', 'resources/clear-all-audio-dataset/', {
                id: dataSet.id,
                clear: true
            });
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    const createMatchDataSet = async() => {
        // const res = await updateDataAPI('POST', 'resources/create-match-data-set-from-another-data-set/', {
        //     id: dataSet.id
        // });
        // if (res.data['success']) {
        //     window.open('/data-set?id=' + res.data['newDataSetID'], '_blank', 'noopener,noreferrer');
        // } else {
        //     window.alert('Error', res.data['error']);
        // }
    }

    const refreshAllDesignRows = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/refresh-all-design-rows/?dataSetID=' + dataSet.id);
        setLoading(false);
    }

    return (
        <>
            <tr key={dataSet.id} className="data-set-row">
                <td className="id-cell">
                    <a href={'/data-set/?id=' + dataSet.id} target="_blank">{dataSet.id}</a>
                    {dataSet.validated && (
                        <Alert variant="success" size="sm">
                            <div>Validated</div>
                            <div>{dataSet.nbQuestionsValidated && dataSet.nbQuestionsValidated + ' rows'}</div>
                        </Alert>
                    )}
                    {dataSet.skipped && (<div>Skipped</div>)}
                </td>
                <td className="name-cell">
                    <div className="event-groups-page__group-name">
                        <ListGroup className="dataset-info">
                            {dataSet.keywords.map(keywordID => (
                                <ListGroup.Item key={keywordID} onClick={() => setKeywordInInfoModal(keywordID)} className="keyword-label">
                                    {keywordID}. {dataSet.keywordsData.find(k => k.id === keywordID)?.name}
                                </ListGroup.Item>
                            ))}
                            <ListGroup.Item>
                                Data Type: {dataSet.dataType}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Activity: {dataSet.activity}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Quality: {dataSet.quality}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Store: {dataSet.store}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Themes: {dataSet.seasons}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Source: {dataSet.dataSource}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Total: {showContent ? rows.length : dataSet.nbTotalRows} rows
                            </ListGroup.Item>
                            {dataSet.template && showContent && (
                                <ListGroup.Item>
                                    <a href={Constant.WORKSHEET_MAKER_URL + '?type=template&id=' + dataSet.template + '&token=' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)} target="_blank">Linked to template: {dataSet.template}</a>
                                </ListGroup.Item>
                            )}
                            {dataSet.collection && showContent && (
                                <ListGroup.Item>
                                    Linked to collection: {dataSet.collection}
                                </ListGroup.Item>
                            )}
                            {showContent && (
                                <ListGroup.Item>
                                    <div>Mapping:</div>
                                    {dataSet.mapping && (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>From</th>
                                                    <th>To</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(dataSet.mapping).map((key) => (
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>{dataSet.mapping[key]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </div>
                </td>
                {showContent && (
                    <td className="data-set-cell">
                        {dataSet.dataType === 'DESIGN' ? (
                            <DataSetDesignRows
                                setLoading={setLoading}
                                dataSet={dataSet}
                            />
                        ) : (
                            <DataSetData
                                dataSet={dataSet}
                                rows={rows}
                                setRows={setRows}
                                fetchAllRows={fetchAllRows}
                                updateIcons={updateIcons}
                                updateMultiIconsPerRow={updateMultiIconsPerRow}
                                setLoading={setLoading}
                            />
                        )}
                    </td>
                )}
                {showContent && (
                    <td className="actions-cell">
                        <div>
                            <Dropdown as={ButtonGroup} size="sm">
                                <Button variant="info" onClick={handleSaveAndValidateDataSet}>
                                    Validate
                                </Button>

                                <Dropdown.Toggle split id="validate-dropdown" variant="info" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={skipDataSet}>Skip</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {dataSet.dataType === 'JSON' && (
                                <Dropdown as={ButtonGroup} size="sm">

                                    <Button onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('JSON');
                                        setSelectedDataSet(dataSet);
                                    }}>Insert JSON</Button>

                                    <Dropdown.Toggle split id="insert-json-dropdown" />

                                    <Dropdown.Menu>
                                        {/* <Dropdown.Item onClick={() => setShowDataSetModal(true)}>Upload Icons</Dropdown.Item>
                                        <Dropdown.Item onClick={() => navigator.clipboard.writeText(JSON.stringify(dataSet.topics, null, 4))}>Copy JSON</Dropdown.Item> */}
                                        <Dropdown.Item onClick={() => setShowUpdateJSONDataSetModal(true)}>Update JSON</Dropdown.Item>
                                        {/* <Dropdown.Item onClick={() => setShowDataSetIconsManagementModal(true)}>Icons Management</Dropdown.Item>
                                        <Dropdown.Item onClick={createMatchDataSet}>Create New Match Set from this Set</Dropdown.Item>*/}
                                        <Dropdown.Item onClick={() => {
                                            setShowInsertDataSetModal(true);
                                            setInsertDataType('kahoot_links');
                                            setSelectedDataSet(dataSet);
                                        }}>By Kahoot Links</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            {dataSet.dataType === 'DESIGN' && (
                                <Dropdown as={ButtonGroup} size="sm">

                                    <Button onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('JSON');
                                        setSelectedDataSet(dataSet);
                                    }}>Insert JSON</Button>

                                    <Dropdown.Toggle split id="insert-json-dropdown" />

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            setShowInsertDataSetModal(true);
                                            setInsertDataType('tinytap');
                                            setSelectedDataSet(dataSet);
                                        }}>
                                            Insert By TinyTap
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            {/* {dataSet.dataType === 'TABLE'}
                                <Dropdown as={ButtonGroup} size="sm">
                                    <Button>
                                        Insert Data
                                    </Button>

                                    <Dropdown.Toggle split id="insert-data-dropdown" />

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            setShowInsertDataSetModal(true);
                                            setInsertDataType('sheet');
                                            setSelectedDataSet(dataSet);
                                        }}>By Sheet</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            setShowInsertDataSetModal(true);
                                            setInsertDataType('quizizz_links');
                                            setSelectedDataSet(dataSet);
                                        }}>By Quizizz Links</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            setShowInsertDataSetModal(true);
                                            setInsertDataType('quizizz keyword');
                                            setSelectedDataSet(dataSet);
                                        }}>By Quizizz Keyword</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            setShowInsertDataSetModal(true);
                                            setInsertDataType('ace_links');
                                            setSelectedDataSet(dataSet);
                                        }}>By ACE Links</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )} */}

                            <Button
                                size="sm"
                                variant="danger"
                                onClick={() => handleDeleteDataSet(dataSet.id)}
                            >
                                Delete
                            </Button>

                            <Button size="sm" onClick={() => setShowDataSetModal(true)}>Edit Info</Button>
                            {dataSet.validated && dataSet.nbQuestionsValidated > 0 && (
                                <Dropdown as={ButtonGroup} size="sm">
                                    <Button size="sm" onClick={genAudio}>Gen Audio</Button>

                                    <Dropdown.Toggle split id="clear-and-gen-audio-dropdown" />

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={clearAllAudio}>Clear All Audios</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            <Button size="sm" variant="info" onClick={() => handleDuplicateDataSet(dataSet.id)}>
                                Duplicate
                            </Button>

                            {dataSet.dataType === 'DESIGN' && (
                                <Button size="sm" variant="success" onClick={refreshAllDesignRows}>Refresh all</Button>
                            )}
                        </div>
                    </td>
                )}
            </tr>
            {showInsertDataSetModal && (
                <InsertDataSetModal
                    type={insertDataType}
                    handleInsertData={handleInsertData}
                    hideModal={() => setShowInsertDataSetModal(false)}
                />
            )}
            {showUpdateJSONDataSetModal && (
                <UpdateJSONDataSetModal
                    dataSet={dataSet}
                    rows={rows}
                    setRows={setRows}
                    setLoading={setLoading}
                    hideModal={() => setShowUpdateJSONDataSetModal(false)}
                />
            )}
            {showDataSetIconsManagementModal && (
                <DataSetIconsManagementModal
                    dataSet ={dataSet}
                    updateIcons={updateIcons}
                    hideModal={() => setShowDataSetIconsManagementModal(false)}
                />
            )}
            {showDataSetModal && (
                <DataSetModal
                    dataSet={dataSet}
                    updateDataSet={updateDataSet}
                    hideModal={() => setShowDataSetModal(false)}
                />
            )}

            {keywordInInfoModal && (
                <KeywordInfoModal
                    keywordID={keywordInInfoModal}
                    hideModal={() => setKeywordInInfoModal(null)}
                    updateKeyword={(keyword) => {
                        setDataSet({...dataSet, keywordData: keyword});
                    }}
                    removeKeyword={() => {
                        setDataSet({...dataSet, keyword: null, keywordData: null});
                    }}
                />
            )}
        </>
    )
}

export default DataSetRow;