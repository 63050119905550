import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { updateDataAPI } from '../../../../utils/query';

const InsertDataSetModal = ({
    type = 'TABLE',
    handleInsertData = (value) => { },
    hideModal = () => { }
}) => {
    const [insertValues, setInsertValues] = useState('');
    const [links, setLinks] = useState('');
    const [quizizzKeyword, setQuizizzKeyword] = useState('');
    const [quizizzGrades, setQuizizzGrades] = useState('');
    const [nbPages, setNbPages] = useState(5);

    const checkNbResults = async() => {
        const res = await updateDataAPI('POST', 'resources/check-nb-results-by-kahoot-link/', {
            urls: links.split('\n')
        });
        window.alert('New rows: ' + res.data['nbResults']);
    }

    return (
        <Modal show={true} onHide={hideModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Insert data by {type}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === 'JSON' && (
                    <Form.Group className="mb-3" controlId="jsonTextArea">
                        <Form.Label>JSON</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={insertValues}
                            rows={20}
                            onChange={(e) => setInsertValues(e.target.value)}
                            wrap='off'
                        />
                    </Form.Group>
                )}

                {type === 'TABLE' && (
                    <Form.Group className="mb-3" controlId="tableTextArea">
                        <Form.Label>Data set</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={insertValues}
                            onChange={(e) => setInsertValues(e.target.value)}
                            wrap='off'
                        />
                    </Form.Group>
                )}

                {type === 'tinytap' && (
                    <Form.Group className="mb-3" controlId="tableTextArea">
                        <Form.Label>TinyTap ID</Form.Label>
                        <Form.Control
                            value={insertValues}
                            onChange={(e) => setInsertValues(e.target.value)}
                        />
                    </Form.Group>
                )}

                {(type === 'quizizz_links' || type === 'ace_links' || type === 'kahoot_links') && (
                    <Form.Group className="mb-3" controlId="urls">
                        <Form.Label>Url</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={links}
                            onChange={(e) => setLinks(e.target.value)}
                        />
                        <Form.Text>{links.split('\n').length} links</Form.Text>
                    </Form.Group>
                )}

                {type === 'quizizz keyword' && (
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="quizizzKeyword">
                                    <Form.Label>Keyword</Form.Label>
                                    <Form.Control
                                        value={quizizzKeyword}
                                        onChange={(e) => setQuizizzKeyword(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="quizizzKeyword">
                                    <Form.Label>Grades</Form.Label>
                                    <Form.Control
                                        value={quizizzGrades}
                                        onChange={(e) => setQuizizzGrades(e.target.value)}
                                    />
                                    <Form.Text>Separated by , (ex: 1,2)</Form.Text>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="quizizzNbPages">
                                    <Form.Label>Nb Pages</Form.Label>
                                    <Form.Control
                                        value={nbPages}
                                        onChange={(e) => setNbPages(parseInt(e.target.value))}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Cancel
                </Button>
                {type === 'kahoot_links' && (
                    <Button onClick={checkNbResults}>Check Nb Results</Button>
                )}
                {(
                    ((type === 'TABLE' || type === 'JSON' || type === 'tinytap') && insertValues !== '') ||
                    ((type === 'quizizz_links' || type === 'ace_links' || type === 'kahoot_links') && links !== '') ||
                    (type === 'quizizz keyword' && quizizzKeyword !== '' && quizizzGrades !== '')
                ) && (
                    <Button variant="primary" onClick={() => handleInsertData(insertValues, links, quizizzKeyword, quizizzGrades, nbPages)}>
                        Insert
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default InsertDataSetModal;