import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';

const ActivityModal = ({ activity = null, hideModal, duplicating = false }) => {
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState(activity?.label);
    const [slug, setSlug] = useState(activity?.slug);
    const [templateRequirements, setTemplateRequirements] = useState(activity ? JSON.stringify(activity.templateRequirements, null, 4) : null);
    const [metaInfo, setMetaInfo] = useState(activity ? JSON.stringify(activity.metaInfo, null, 4) : null);
    const [hasSeasonal, setHasSeasonal] = useState(activity ? activity.hasSeasonal : false);

    const save = async() => {
        setLoading(true);
        try {
            const templateRequirementsJSON = JSON.parse(templateRequirements);
            const metaInfoJSON = JSON.parse(metaInfo);
            const data = {
                templateRequirements: templateRequirementsJSON,
                metaInfo: metaInfoJSON,
                hasSeasonal,
            };
            if (duplicating) {
                data['slug'] = slug;
                data['label'] = label;

                await updateDataAPI('PATCH', 'resources/tags/' + activity.tag + '/', {
                    slug: slug,
                    name: label,
                });
            }
            const res = await updateDataAPI('PATCH', 'resources/activities/' + activity.id + '/', data);
            hideModal('save', res.data);
        } catch (err) {
            window.alert('json error');
        }
        setLoading(false);
    }

    const create = async() => {
        setLoading(true);
        const resTags = await getDataFromAPI('resources/tags/?slug=' + slug);
        let tagID;
        if (resTags.data.length === 0) {
            const resNewTag = await updateDataAPI('POST', 'resources/tags/', {
                slug,
                name: label,
            });
            tagID = resNewTag.data['id'];
        } else {
            tagID = resTags.data[0]['id'];
        }
        const res = await updateDataAPI('POST', 'resources/activities/', {
            slug,
            label,
            tag: tagID,
            templateRequirements: JSON.parse(templateRequirements),
            metaInfo: JSON.parse(metaInfo),
            hasSeasonal,
        });
        setLoading(false);
        hideModal('create', res.data);
    }

    return (
        <Modal size="xl"
            show={true}
            onHide={hideModal}
            className="activity-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Activity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="label">
                            <Form.Label>Activity</Form.Label>
                            <Form.Control value={label} onChange={e => setLabel(e.target.value)} disabled={activity !== null && !duplicating} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="label">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control value={slug} onChange={e => setSlug(e.target.value)} disabled={activity !== null && !duplicating} />
                            <Form.Text>Lowercase, replace space to _</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="label">
                            <Form.Check
                                type="checkbox"
                                label="Has Seasonal"
                                id="activity-has-seasonal"
                                onChange={() => setHasSeasonal(!hasSeasonal)}
                                checked={hasSeasonal} />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="templateRequirements">
                            <Form.Label>Template Requirements</Form.Label>
                            <Form.Control
                                value={templateRequirements}
                                onChange={e => setTemplateRequirements(e.target.value)}
                                as="textarea"
                                rows={10}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="metaInfo">
                            <Form.Label>Meta Info</Form.Label>
                            <Form.Control
                                value={metaInfo}
                                onChange={e => setMetaInfo(e.target.value)}
                                as="textarea"
                                rows={10}
                            />
                        </Form.Group>
                        <Form.Text>
                            <pre>
                                {JSON.stringify({keywordsInTitle: ['Task Cards'], prices: [{minPages: 0, price: 1}], keywords: ['task cards']}, null, 4)}
                            </pre>
                        </Form.Text>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {activity ? (
                    <Button onClick={save}>Save</Button>
                ) : (
                    <Button onClick={create}>Create</Button>
                )}
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default ActivityModal;