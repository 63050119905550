import { useEffect, useState } from 'react';
import { Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import DataSetModal from '../../components/dataset/data-set-row/data-set-modal';
import { useSelector } from 'react-redux';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import DataSetRow from '../../components/dataset/data-set-row';
import PagePagination from '../../components/auto-content/templates-management/page-pagination';
import FilterByTheme from '../../filters/theme';

const DataSetsPage = () => {
    const stores = useSelector(state => state.filters.stores);
    const activities = useSelector(state => state.filters.activities);

    const [loading, setLoading] = useState(false);

    const [searchKeyword, setSearchKeyword] = useState(null);
    const [filteredDataType, setFilteredDataType] = useState('all');
    const [filteredActivity, setFilteredActivity] = useState('all');
    const [filteredStoreID, setFilteredStoreID] = useState('all');
    const [filteringTheme, setFilteringTheme] = useState('all');
    const [dataSets, setDataSets] = useState([]);

    const [showDataSetModal, setShowDataSetModal] = useState(false);

    const pageSize = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchDataSets();
    }, []);

    const fetchDataSets = async (pageIndex = currentPage) => {
        setLoading(true);
        let url = `resources/data-sets/?page=${pageIndex}&page_size=${pageSize}`;

        if (searchKeyword) {
            url += '&searchKeyword=' + searchKeyword;
        }

        if (filteredDataType !== 'all') {
            url += '&dataType=' + filteredDataType;
        }

        if (filteredActivity !== 'all') {
            url += '&activity=' + filteredActivity;
        }

        if (filteredStoreID !== 'all') {
            url += '&storeID=' + filteredStoreID;
        }

        if (filteringTheme !== 'all') {
            url += '&theme=' + filteringTheme;
        }

        const res = await getDataFromAPI(url);

        if (res) {
            const data = res.data;
            setTotalPages(Math.ceil(data.count / pageSize))
            const results = data.results;
            results.sort((a, b) => b.id - a.id);
            setDataSets(results);
        }

        setLoading(false);
    }

    const search = async(e) => {
        setCurrentPage(1);
        fetchDataSets(1);
    }

    const handleDeleteDataSet = async(dataSetID) => {
        setLoading(true);
        await updateDataAPI('DELETE', `resources/data-sets/${dataSetID}/`);
        setDataSets(dataSets.filter(ds => ds.id !== dataSetID));
        setLoading(false);
    }

    const handleDuplicateDataSet = async (dataSetID) => {
        const confirmed = window.confirm('Are you sure want to duplicate this data set?');
        if (confirmed) {
            setLoading(true);
            const res = await updateDataAPI('POST', `resources/duplicate-data-set/?id=${dataSetID}`);
            setDataSets(dataSets.concat([res.data]));
            setLoading(false);
        }
    }

    return (
        <Container className="data-sets-page">
            <div className="data-set-header">
                <div className="filters">
                    <Form.Control className="search-input" value={searchKeyword} onChange={e => setSearchKeyword(e.target.value)} placeholder="Search Data Set" />
                    <Form.Select value={filteredDataType} onChange={e => setFilteredDataType(e.target.value)}>
                        <option value="all">All DataType</option>
                        <option value="JSON">JSON</option>
                        <option value="TABLE">TABLE</option>
                    </Form.Select>
                    <Form.Select value={filteredActivity} onChange={e => setFilteredActivity(e.target.value)}>
                        <option value="all">All Activities</option>
                        {activities.map((activity, index) => (
                            <option key={index} value={activity['slug']}>{activity['label']}</option>
                        ))}
                    </Form.Select>
                    <Form.Select value={filteredStoreID} onChange={e => setFilteredStoreID(e.target.value)}>
                        <option value="all">All Stores</option>
                        {stores.map((store, index) => (
                            <option key={index} value={store.id}>Store {store.id}</option>
                        ))}
                    </Form.Select>
                    <FilterByTheme filteringTheme={filteringTheme} setFilteringTheme={setFilteringTheme} valueField="slug" />
                    <Button onClick={search}>Search</Button>
                </div>
                <div className="header-right">
                    <Button variant="primary" type="submit" onClick={() => setShowDataSetModal(true)}>
                        Create Data Set
                    </Button>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Info</th>
                    </tr>
                </thead>
                <tbody>
                    {dataSets.map(dataSet => (
                        <DataSetRow
                            key={dataSet.id}
                            dataSet={dataSet}
                            setDataSet={dataSetData => setDataSets(dataSets.map(ds => {
                                if (ds.id === dataSet.id) {
                                    return dataSetData;
                                }

                                return ds;
                            }))}
                            handleDeleteDataSet={handleDeleteDataSet}
                            handleDuplicateDataSet={handleDuplicateDataSet}
                            setLoading={setLoading}
                        />
                    ))}
                </tbody>
            </Table>

            <PagePagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={(pageIndex) => {
                    setCurrentPage(pageIndex);
                    fetchDataSets(pageIndex);
                }}
            />

            {showDataSetModal && (
                <DataSetModal
                    hideModal={() => setShowDataSetModal(false)}
                    setDataSets={setDataSets}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default DataSetsPage;