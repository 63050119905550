import { useEffect, useState } from 'react';
import { Button, Container, Form, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import Constant from '../../Constant';

import './styles.scss';
import FilterByStore from '../../filters/store/index_v1';

const UploadBoomProductsPage = () => {
    const [toPublishBoomProducts, setToPublishBoomProducts] = useState([]);
    const [toReviewBoomProducts, setToReviewBoomProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteringStore, setFilteringStore] = useState('all');

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async() => {
        setLoading(true);
        const resToPublish = await getDataFromAPI('products/?toPublishBoom=true&additionalFields=ideaData');
        setToPublishBoomProducts(resToPublish.data);

        const resToReview = await getDataFromAPI('products/?toReviewBoom=true&additionalFields=ideaData');
        setToReviewBoomProducts(resToReview.data);
        setLoading(false);
    }

    const clearErrorToReValidate = async(productID) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'products/' + productID + '/', {
            boomUploadErrors: null
        });
        setToReviewBoomProducts(toReviewBoomProducts.filter(p => p['id'] !== productID));
        setLoading(false);
    }

    const setProductUploaded = async(productID) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'products/' + productID + '/', {
            boomUploaded: true
        });
        setToPublishBoomProducts(toPublishBoomProducts.filter(p => p['id'] !== productID));
        setLoading(false);
    }

    const reloadProduct = async(productID) => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/reupload-boom-product/?productID=' + productID);
        setToReviewBoomProducts(toReviewBoomProducts.filter(p => p['id'] !== productID));
        setLoading(false);
    }

    return (
        <Container className="upload-boom-products-page">
            <div className="page-header">
                <FilterByStore filteringStore={filteringStore} setFilteringStore={setFilteringStore} marketplace="boom" />
                <Button onClick={fetchProducts}>Refresh</Button>
            </div>
            <Tabs id="upload-boom-tabs" className="mt-5">
                <Tab eventKey="toPublish" title={'To Publish (' + toPublishBoomProducts.filter(product => {
                        if (filteringStore !== 'all' && product.store != filteringStore) {
                            return false;
                        }

                        return true;
                    }).length + ')'}>
                    <Table striped bordered hover>
                        <tbody>
                            {toPublishBoomProducts.filter(product => {
                                if (filteringStore !== 'all' && product.store != filteringStore) {
                                    return false;
                                }

                                return true;
                            }).map((product, index) => (
                                <tr key={product['id']}>
                                    <td>{index + 1}</td>
                                    <td>Store {product.store}</td>
                                    <td>
                                        <a href={Constant.API_URL + 'admin/free_resources/product/' + product['id']} target="_blank">
                                            {product['customID']}
                                        </a>
                                    </td>
                                    <td>
                                        <img src={product['ideaData']['thumbnail']} />
                                        <div>
                                            <a href={'/content-studio/?customID=' + product['customID']} target="_blank">
                                                {product['ideaData']['title']}
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <a href={product['url'].replace('/deck/', '/editDeck/')} target="_blank" rel="noreferrer">
                                            boom link
                                        </a>
                                    </td>
                                    <td>
                                        <Button onClick={() => setProductUploaded(product['id'])}>Uploaded</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="toReview" title={'To Review (' + toReviewBoomProducts.filter(product => {
                    if (filteringStore !== 'all' && product.store != filteringStore) {
                        return false;
                    }

                    return true;
                }).length + ')'}>
                    <Table striped bordered hover>
                        <tbody>
                            {toReviewBoomProducts.filter(product => {
                                if (filteringStore !== 'all' && product.store != filteringStore) {
                                    return false;
                                }

                                return true;
                            }).map((product, index) => (
                                <tr key={product['id']}>
                                    <td>{index + 1}</td>
                                    <td>Store {product.store}</td>
                                    <td>
                                        <a href={Constant.API_URL + 'admin/free_resources/product/' + product['id']} target="_blank">
                                            {product['customID']}
                                        </a>
                                    </td>
                                    <td>
                                        <img src={product['ideaData']['thumbnail']} />
                                        <div>
                                            <a href={'/content-studio/?customID=' + product['customID']} target="_blank">
                                                {product['ideaData']['title']}
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <a href={product['url'].replace('/deck/', '/editDeck/')} target="_blank" rel="noreferrer">
                                            boom link
                                        </a>
                                    </td>
                                    <td className="errors-cell">
                                        <Form.Control as="textarea" value={product['boomUploadErrors']} rows={10} disabled />
                                    </td>
                                    <td>
                                        <div className="btns">
                                            <Button size="sm" onClick={() => clearErrorToReValidate(product['id'])}>Clear Error</Button>
                                            <Button size="sm" onClick={() => reloadProduct(product['id'])}>To Reupload</Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default UploadBoomProductsPage;