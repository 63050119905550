import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import { Button, Container, Form, ProgressBar, Spinner, Table } from 'react-bootstrap';
import SettingDesignTicketModal from '../../components/design-ticket/setting-design-ticket-modal';
import Moment from 'react-moment';
import IdeaModal from '../idea-list/idea-modal';

export const FILTER_RANGE_TIME_DAYS = 2;

const DesignTicketsPage = () => {
    const [tickets, setTickets] = useState([]);
    const [ticketInSettingModal, setTicketInSettingModal] = useState(null);
    const [showSettingTicketModal, setShowSettingTicketModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [authors, setAuthors] = useState([]);
    const [filteredAuthorName, setFilteredAuthorName] = useState('all');
    const [filtedDone, setFilteredDone] = useState('all');
    const [filteredStoreID, setFilteredStoreID] = useState('all');
    const [allStoreIDsInResult, setAllStoreIDsInResult] = useState([]);
    const [ideaInModal, setIdeaInModal] = useState(null);

    useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async() => {
        setLoading(true);
        const res = await getDataFromAPI(`product-dev/design-tickets?filterRangeTimeDays=${FILTER_RANGE_TIME_DAYS}`);
        setTickets(res.data);
        setAllStoreIDsInResult(res.data.reduce((stores, ticket) => {
            if (stores.indexOf(ticket.storeID) === -1) {
                stores.push(ticket.storeID);
            }

            return stores;
        }, []));

        setAuthors(res.data.reduce((authors, ticket) => {
            if (authors.indexOf(ticket.authorName) === -1) {
                authors.push(ticket.authorName);
            }

            return authors;
        }, []));
        setLoading(false);
    }

    const unblockTicket = async(ticketIDs) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            for (let i = 0; i < ticketIDs.length; i++) {
                await updateDataAPI('PATCH', 'product-dev/design-tickets/' + ticketIDs[i] + '/', {nbFails: 0});
            }
            setTickets(tickets.map(t => {
                if (ticketIDs.indexOf(t.id) > -1) {
                    return {...t, nbFails: 0};
                }
                return t;
            }));
            setLoading(false);
        }
    }

    const deleteTicket = async(ticket) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-dev/design-tickets/' + ticket.id + '/');
            setTickets(tickets.filter(t => t.id !== ticket.id));
            setLoading(false);
        }
    }

    const showIdeaModal = async(ideaID) => {
        const res = await getDataFromAPI('product-ideas/' + ideaID + '/?additionalFields=highlightImages');
        setIdeaInModal(res.data);
    }

    return (
        <Container className="design-tickets-page" fluid>
            <h2>Design Tickets</h2>
            <div className="filters">
                <div>
                    <Form.Select value={filteredAuthorName} onChange={e => setFilteredAuthorName(e.target.value)}>
                        <option value="all">all authors</option>
                        {authors.map(author => (
                            <option value={author}>{author}</option>
                        ))}
                    </Form.Select>
                    <Form.Select value={filtedDone} onChange={e => setFilteredDone(e.target.value)}>
                        <option value="all">all statuses</option>
                        <option value="yes">done</option>
                        <option value="no">in progress</option>
                    </Form.Select>
                    <Form.Select value={filteredStoreID} onChange={e => setFilteredStoreID(e.target.value)}>
                        <option value="all">all stores</option>
                        {allStoreIDsInResult.map(storeID => (
                            <option key={storeID} value={storeID}>Store {storeID}</option>
                        ))}
                    </Form.Select>
                </div>
                <div className="btns">
                    <Button onClick={fetchTickets}>Refresh</Button>
                    <Button variant="warning" onClick={() => unblockTicket(tickets.filter(ticket => ticket.nbFails > 0).map(ticket => ticket.id))}>Unblock all</Button>
                </div>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Idea</th>
                        <th>Template</th>
                        <th>Store</th>
                        <th>Reset Design</th>
                        <th>Nb Fails</th>
                        <th>Auto Resize Text</th>
                        <th>Progress</th>
                        <th>Author</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.filter(ticket => {
                        if (filteredAuthorName !== 'all' && ticket.authorName !== filteredAuthorName) {
                            return false;
                        }

                        if (filtedDone !== 'all' && ((ticket.done && filtedDone === 'no') || (!ticket.done && filtedDone === 'yes'))) {
                            return false;
                        }

                        if (filteredStoreID !== 'all' && ticket.storeID != filteredStoreID) {
                            return false;
                        }

                        return true;
                    }).map((ticket, ticketIndex) => (
                        <tr key={ticket.id}>
                            <td>{ticketIndex + 1}</td>
                            <td className="idea-cell">
                                <div className="show-idea-modal" onClick={() => showIdeaModal(ticket.idea)}>
                                    {ticket.customID}. {ticket.ideaTitle}
                                </div>
                            </td>
                            <td>{ticket.template}</td>
                            <td>{ticket.storeID}</td>
                            <td>
                                {ticket.resetDesignItems && (
                                    <span>{ticket.resetDesignItems.join(', ')}</span>
                                )}
                            </td>
                            <td>{ticket.nbFails}</td>
                            <td>{ticket.autoResizeText ? 'Yes' : ''}</td>
                            <td>
                                <ProgressBar striped variant={ticket.done ? 'success' : 'warning'}
                                    now={ticket.done ? 100 : Math.floor(((ticket.finishedPages ? ticket.finishedPages.length : 0) / ticket.totalPages) * 100)} />
                            </td>
                            <td>{ticket.authorName}</td>
                            <td>
                                <Moment date={ticket.createdAt} format="hh:mm" durationFromNow />
                                <span> ago</span>
                            </td>
                            <td>
                                <div className="btns">
                                    <Button size="sm" variant="primary" onClick={() => {
                                        setTicketInSettingModal(ticket);
                                        setShowSettingTicketModal(true);
                                    }} className="edit-btn">Edit</Button>
                                    <Button size="sm" variant="danger" onClick={() => deleteTicket(ticket)}>Delete</Button>
                                    <Button size="sm" variant="warning" onClick={() => unblockTicket([ticket.id])}>Unblock</Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showSettingTicketModal && (
                <SettingDesignTicketModal
                    initialTicket={ticketInSettingModal}
                    hideModal={action => {
                        setTicketInSettingModal(null);
                        setShowSettingTicketModal(false);
                        if (action === 'refresh') {
                            fetchTickets();
                        }
                    }} />
            )}

            {ideaInModal !== null && (
                <IdeaModal
                    idea={ideaInModal}
                    closeModal={() => {
                        setIdeaInModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default DesignTicketsPage;