import { useEffect, useState } from 'react';
import { Button, Container, Form, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import StoreGroupModal from '../../components/store-groups/store-group-modal';

const StoreGroupsPage = () => {
    const [storeGroups, setStoreGroups] = useState([]);
    const [storeGroupInModal, setStoreGroupInModal] = useState(null);
    const [showStoreGroupModal, setShowStoreGroupModal] = useState(false);

    useEffect(() => {
        fetchStoreGroups();
    }, []);

    const fetchStoreGroups = async() => {
        const res = await getDataFromAPI('resources/store-groups/');
        setStoreGroups(res.data);
    }

    return (
        <Container className="activities-page">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Label</th>
                        <th>Activities</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {storeGroups.map((storeGroup, index) => (
                        <tr key={storeGroup.id}>
                            <td>{index + 1}</td>
                            <td>{storeGroup.label}</td>
                            <td>
                                <Form.Control as="textarea" value={storeGroup.activities.join('\n')} rows={10} />
                            </td>
                            <td>
                                <Button size="sm" onClick={() => {
                                    setStoreGroupInModal(storeGroup);
                                    setShowStoreGroupModal(true);
                                }}>Edit</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showStoreGroupModal && (
                <StoreGroupModal
                    storeGroup={storeGroupInModal}
                    hideModal={(action, data) => {
                        if (action === 'save') {
                            setStoreGroups(storeGroups.map(storeGroup => {
                                if (storeGroup.id === storeGroupInModal.id) {
                                    return data;
                                }

                                return storeGroup;
                            }));
                        }
                        setStoreGroupInModal(null);
                        setShowStoreGroupModal(false);
                    }}
                />
            )}
        </Container>
    )
}

export default StoreGroupsPage;