import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { Button, Form, Spinner } from 'react-bootstrap';

import './styles.scss';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

const StoreDescriptionTemplatesTab = ({storeID}) => {
    const [descriptionTemplates, setDescriptionTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStoreDescriptionTemplates();
        fetchStoreGroup();
    }, [storeID]);

    const fetchStoreGroup = async() => {
        const res = await getDataFromAPI('resources/store-groups/?storeID=' + storeID);
    }

    const fetchStoreDescriptionTemplates = async () => {
        setLoading(true);
        const res = await getDataFromAPI('resources/store-activity-description-templates/?storeID=' + storeID);
        setDescriptionTemplates(res.data);
        setLoading(false);
    }

    const save = async () => {
        setLoading(true);
        for (let i = 0; i < descriptionTemplates.length; i++) {
            await updateDataAPI('PATCH', 'resources/store-activity-description-templates/' + descriptionTemplates[i].id + '/', {
                description: descriptionTemplates[i].description
            });
        }
        window.alert('Description templates saved successfully');
        setLoading(false);
    }

    const autoGen = async(templateID) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/auto-gen-description-template/', {
            templateID
        });
        setDescriptionTemplates(descriptionTemplates.map(t => {
            if (t.id === templateID) {
                return {...t, description: res.data};
            }
            return t;
        }));
        setLoading(false);
    }

    return (
        <Form className="store-description-templates-tab">
            <div>
                To replace:
                #subjects, #grades, #title, #nbPages, #activityTags, #skillsOrThemes
            </div>
            <div className="description-templates">
                {descriptionTemplates.map(template => (
                    <div key={template.id}>
                        <h2>
                            <span>{template.activityData.label} - {template.saleStatus}</span>
                            <FontAwesomeIcon icon={faLightbulb} className="auto-gen-icon text-warning" onClick={() => autoGen(template.id)} />
                        </h2>
                        <ReactQuill theme="snow" value={template.description} onChange={value => setDescriptionTemplates(descriptionTemplates.map(t => {
                            if (t.id === template.id) {
                                return {...t, description: value};
                            }
                            return t;
                        }))} />
                    </div>
                ))}
            </div>
            <Button className="mt-5" onClick={save}>Save</Button>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Form>
    )
}

export default StoreDescriptionTemplatesTab;