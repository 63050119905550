import { Container, Tab, Tabs } from 'react-bootstrap';

import './styles.scss';
import StoreKeywords from '../../components/store-map/store-keywords-table';
import OrphanKeywords from '../../components/store-map/orphan-keywords';

const StoreMapPage = () => {
    return (
        <Container className="store-map-page" fluid>
            <Tabs>
                <Tab eventKey="storeKeywords" title="Store Keywords">
                    <StoreKeywords />
                </Tab>
                <Tab eventKey="orphanKeywords" title="Orphan Keywords">
                    <OrphanKeywords />
                </Tab>
            </Tabs>
        </Container>
    );
}

export default StoreMapPage;