import { useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner, Table } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import { useSelector } from 'react-redux';
import KeywordInfoModal from '../../product-map/keyword-info-modal';

const ImportNewKeywordsByJSONModal = ({ refreshKeywords, hideModal }) => {
    const { selectedStoreID } = useSelector(state => state.filters);

    const [jsonContent, setJsonContent] = useState(null);
    const [newKeywords, setNewKeywords] = useState(null);
    const [existingKeywords, setExistingKeywords] = useState({});
    const [loading, setLoading] = useState(false);
    const [existingKeywordInInfoModal, setExistingKeywordInInfoModal] = useState(null);

    const importJSON = async () => {
        if (window.confirm('Are you sure')) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/import-new-keywords-by-json/?storeID=' + selectedStoreID, newKeywords);
            setLoading(false);
            refreshKeywords();
            hideModal();
        }
    }

    const formatJSONContent = async () => {
        setLoading(true);
        try {
            const formattedJSONContent = JSON.parse(jsonContent);
            const newKeywords = formattedJSONContent.map(keyword => {
                return {
                    name: keyword['mainKeyword'].toLowerCase(),
                    grades: keyword['grades'].join(','),
                    subKeywords: keyword['subKeywords'].join(','),
                    subjects: keyword['subjects'].join(','),
                    category: keyword['category'],
                }
            });
            setNewKeywords(newKeywords);
            const res = await updateDataAPI('POST', 'resources/find-existing-keywords/', newKeywords);
            setExistingKeywords(res.data);
        } catch (err) {
            window.alert('json error');
        }
        setLoading(false);
    }

    const updateKeyword = (index, field, value) => {
        setNewKeywords(prev => prev.map((k, kIndex) => {
            if (kIndex === index) {
                return { ...k, [field]: value };
            }
            return k;
        }))
    }

    const deleteNewKeyword = (index) => {
        setNewKeywords(prev => prev.filter((k, kIndex) => kIndex !== index));
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="import-new-keywords-by-json-modal"
            fullscreen={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Import New Keywords By JSON</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Label>
                        JSON Content
                    </Form.Label>
                    <Form.Control as="textarea" value={jsonContent} onChange={e => setJsonContent(e.target.value)} rows={10} />
                </Form>
                {newKeywords && (
                    <div className="mt-3">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Sub Keywords</th>
                                    <th>Grades</th>
                                    <th>Category</th>
                                    <th>Subjects</th>
                                    <th>Existing Keywords</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newKeywords.map((keyword, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div>{index + 1}</div>
                                            <div>
                                                <Button size="sm" variant="danger" onClick={() => deleteNewKeyword(index)}>Delete</Button>
                                            </div>
                                        </td>
                                        <td>
                                            <Form.Control
                                                value={keyword['name']}
                                                onChange={e => updateKeyword(index, 'name', e.target.value)}
                                                as="textarea"
                                                rows={2} />
                                        </td>
                                        <td>
                                            <Form.Control value={keyword['subKeywords']} onChange={e => updateKeyword(index, 'subKeywords', e.target.value)} />
                                        </td>
                                        <td>
                                            <Form.Control value={keyword['grades']} onChange={e => updateKeyword(index, 'grades', e.target.value)} />
                                        </td>
                                        <td>
                                            <Form.Control value={keyword['category']} onChange={e => updateKeyword(index, 'category', e.target.value)} />
                                        </td>
                                        <td>
                                            <Form.Control value={keyword['subjects']} onChange={e => updateKeyword(index, 'subjects', e.target.value)} />
                                        </td>
                                        <td>
                                            {existingKeywords[keyword['name']] && (
                                                <ListGroup>
                                                    {existingKeywords[keyword['name']].map(k => (
                                                        <ListGroup.Item key={k.id} onClick={() => setExistingKeywordInInfoModal(k)}>
                                                            <div>{k.id}. {k.name}</div>
                                                            <div>Grades {k.grades}</div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Modal.Body>
            {jsonContent && selectedStoreID && (
                <Modal.Footer>
                    <Button onClick={formatJSONContent}>Format</Button>
                    <Button onClick={importJSON}>Import</Button>
                </Modal.Footer>
            )}

            {existingKeywordInInfoModal && (
                <KeywordInfoModal
                    keywordID={existingKeywordInInfoModal.id}
                    hideModal={() => setExistingKeywordInInfoModal(null)}
                    updateKeyword={(keyword) => {
                        setExistingKeywords({...existingKeywords, [keyword.name]: existingKeywords[keyword.name].map(k => {
                            if (k.id === keyword.id) {
                                return keyword;
                            }
                            return k;
                        })});
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default ImportNewKeywordsByJSONModal;