import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../../../utils/query';
import ImageWrapper from '../../../../common/image-wrapper';

import './styles.scss';

const DataSetDesignRowTemplatePreview = ({templateID}) => {
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        fetchTemplate();
    }, []);

    const fetchTemplate = async() => {
        const res = await getDataFromAPI('auto-content/templates/' + templateID + '/');
        setTemplate(res.data);
    }

    if (template === null) {
        return;
    }

    return (
        <div className="dataset-design-row-template-preview">
            {template.imageUrl && (
                <ImageWrapper src={template.imageUrl} />
            )}
            <div>
                <a href={process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=template&id=' + template.id} target="_blank" rel="noreferrer">
                    {template.id}. {template.name}
                </a>
            </div>
        </div>
    )
}

export default DataSetDesignRowTemplatePreview;