import { useEffect, useState } from 'react';
import AsyncSelectComponent from '../../../utils/async-select';
import { Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const KeywordBundleModal = ({bundle = null, hideModal, loading, setLoading, refreshStore, storeID}) => {
    const [name, setName] = useState(bundle ? bundle.name : null);
    const [keywords, setKeywords] = useState(bundle ? bundle.keywords.map(k => k.id) : []);
    const [keywordLabels, setKeywordLabels] = useState({});
    const [validated, setValidated] = useState(bundle ? bundle.validated : false);

    useEffect(() => {
        if (bundle) {
            const keywordLabels = {};
            for (let i = 0; i < bundle.keywords.length; i++) {
                keywordLabels[bundle.keywords[i].id] = bundle.keywords[i].name;
            }
            setKeywordLabels(keywordLabels);
        }
    }, []);

    const save = async() => {
        setLoading(true);
        await updateDataAPI('PATCH', 'resources/keyword-bundles/' + bundle.id + '/', {
            name,
            keywords,
            validated,
        });
        refreshStore(storeID);
        setLoading(false);
        hideModal();
    }

    const removeKeyword = async(keywordID) => {
        setLoading(true);
        setKeywords(keywords.filter(id => id !== keywordID));
        setLoading(false);
    }

    const createBundle = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/keyword-bundles/', {
            name,
            keywords,
            boomStore: storeID,
        });
        refreshStore(storeID);
        setLoading(false);
        hideModal();
    }

    const getBundleTitle = () => {
        if (name) {
            let title = name + ' Bundle - ';
            for (let i = 0; i < keywords.length; i++) {
                title += keywordLabels[keywords[i]] + ', ';
                if (title.length >= 60) {
                    title += '...';
                    break;
                }
            }
            return title;
        }
        return null;
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="keyword-bundle-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Bundle
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control value={name} onChange={e => setName(e.target.value)} />
                    <Form.Text>Title: {getBundleTitle()}</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Check checked={validated} onChange={e => setValidated(!validated)} label="Validated" />
                </Form.Group>

                <div>Keywords</div>
                <ListGroup>
                    {keywords.map(keyword => (
                        <ListGroup.Item key={keyword} className="keyword">
                            <span>{keyword}. {keywordLabels[keyword]}</span>
                            <FontAwesomeIcon icon={faClose} onClick={() => removeKeyword(keyword)} />
                        </ListGroup.Item>
                    ))}
                </ListGroup>

                <div className="mt-3">Add Keyword to bundle:</div>
                <AsyncSelectComponent
                    searchUrl={'resources/keywords/?storeID=' + storeID + '&name='}
                    onChange={selectedValue => {
                        setKeywords(keywords.concat(selectedValue.id));
                        setKeywordLabels({...keywordLabels, [selectedValue.id]: selectedValue.label});
                    }}
                    labelField="label"
                    value={{ value: '', label: '' }}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => {}}
                    defaultData={[]}
                    createable={false}
                />
            </Modal.Body>
            <Modal.Footer>
                {bundle ? (
                    <Button onClick={save}>Save</Button>
                ) : (
                    <Button onClick={createBundle}>Create Bundle</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default KeywordBundleModal;