import { useState } from 'react';
import { Button, Container, Spinner, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ActivityModal from '../../components/activities/activity-modal';
import { setActivities } from '../../filters/filtersSlice';

import './styles.scss';
import { updateDataAPI } from '../../utils/query';

const ActivitiesPage = () => {
    const dispatch = useDispatch();
    const activities = useSelector(state => state.filters.activities);
    const [activityInModal, setActivityInModal] = useState(null);
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [loading, setLoading] = useState(false);

    const duplicateActivity = async(activity) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/activities/', {
            slug: activity['slug'] + '_dup',
            label: activity['label'] + '_dup',
            tag: activity['tag'],
            templateRequirements: activity['templateRequirements'],
            metaInfo: activity['metaInfo'],
            hasSeasonal: activity['hasSeasonal'],
        });
        const newActivity = res.data;
        dispatch(setActivities(activities.concat([newActivity])));
        setActivityInModal(newActivity);
        setShowActivityModal(true);
        setDuplicating(true);
        setLoading(false);
    }

    const deleteActivity = async(activityID) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'resources/activities/' + activityID + '/');
            dispatch(setActivities(activities.filter(activity => activity.id !== activityID)));
            setLoading(false);
        }
    }

    return (
        <Container className="activities-page">
            <div className="activities-header">
                <Button size="sm" className="mb-3" onClick={() => setShowActivityModal(true)}>New Activity</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Activity</th>
                        <th>Has Seasonal</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map((activity, index) => (
                        <tr key={activity.id}>
                            <td>{index + 1}</td>
                            <td>{activity.label}</td>
                            <td>{activity.hasSeasonal ? 'Yes' : ''}</td>
                            <td>
                                <div className="btns">
                                    <Button size="sm" onClick={() => {
                                        setActivityInModal(activity);
                                        setShowActivityModal(true);
                                    }}>Edit</Button>
                                    <Button size="sm" variant="info" onClick={() => duplicateActivity(activity)}>Duplicate</Button>
                                    <Button size="sm" variant="danger" onClick={() => deleteActivity(activity.id)}>Delete</Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showActivityModal && (
                <ActivityModal
                    activity={activityInModal}
                    duplicating={duplicating}
                    hideModal={(action, data) => {
                        if (action === 'save') {
                            dispatch(setActivities(activities.map(activity => {
                                if (activity.id === activityInModal.id) {
                                    return data;
                                }

                                return activity;
                            })));
                        } else if (action === 'create') {
                            dispatch(setActivities(activities.concat([data])));
                        }
                        setActivityInModal(null);
                        setShowActivityModal(false);
                        setDuplicating(false);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ActivitiesPage;