import { useState } from 'react';
import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';

const StoreGroupModal = ({ storeGroup, hideModal }) => {
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState(JSON.stringify(storeGroup.activities, null, 4));

    const save = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('PATCH', 'resources/store-groups/' + storeGroup.id + '/', {
                activities: JSON.parse(activities),
            });
            hideModal('save', res.data);
        } catch (err) {
            window.alert('json error');
        }
        setLoading(false);
    }

    return (
        <Modal size="xl"
            show={true}
            onHide={hideModal}
            className="store-group-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Store Group {storeGroup.id} - {storeGroup.label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Form.Group className="mb-3" controlId="activities">
                        <Form.Label>Activities</Form.Label>
                        <Form.Control
                            value={activities}
                            onChange={e => setActivities(e.target.value)}
                            as="textarea"
                            rows={10}
                        />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={save}>Save</Button>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default StoreGroupModal;