import { useEffect, useState } from 'react';
import { Col, Form, ListGroup, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AsyncSelectComponent from '../../../../utils/async-select';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';

import './styles.scss';
import Constant from '../../../../Constant';
import DataSetMappingTable from './mapping-table';
import { useSelector } from 'react-redux';
import MultiValues from '../../../../forms/multi-values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const DataSetModal = ({
    dataSet = null,
    setDataSets,
    updateDataSet,
    hideModal = () => { }
}) => {
    const allThemes = useSelector(state => state.filters.themes);
    const stores = useSelector(state => state.filters.stores);

    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState(dataSet?.keywords ?? []);
    const [dataType, setDataType] = useState(dataSet?.dataType);
    const [activity, setActivity] = useState(dataSet?.activity);
    const [quality, setQuality] = useState(dataSet?.quality);
    const [seasons, setSeasons] = useState(dataSet?.seasons);
    const [dataSource, setDataSource] = useState(dataSet?.dataSource ? dataSet.dataSource : 'collect');
    const [template, setTemplate] = useState(dataSet?.template);
    const [collection, setCollection] = useState(dataSet?.collection);
    const [mapping, setMapping] = useState(dataSet?.mapping);
    const [audioAvailable, setAudioAvailable] = useState(dataSet?.audioAvailable);
    const [followedDataSet, setFollowedDataSet] = useState(dataSet?.followedDataSet);
    const [storyName, setStoryName] = useState(dataSet?.storyName);
    const [randomOrder, setRandomOrder] = useState(dataSet ? dataSet.randomOrder : true);
    const [allActivities, setAllActivities] = useState([]);
    const [store, setStore] = useState(dataSet?.store);

    useEffect(() => {
        const fetchAllActivities = async() => {
            const res = await getDataFromAPI('resources/get-all-activities/');
            setAllActivities(res.data);
        }

        fetchAllActivities();
    }, []);

    const saveInfo = async() => {
        if (dataSet) {
            updateDataSet(keywords, dataType, activity, dataSource, template, collection, mapping, quality, seasons, audioAvailable, followedDataSet, storyName, randomOrder, store);
        } else {
            const res = await updateDataAPI('POST', 'resources/data-sets/', {
                dataType,
                activity,
                dataSource,
                keywords,
                template,
                collection,
                quality,
                seasons,
                audioAvailable,
                followedDataSet,
                storyName,
                randomOrder,
                store,
            });
            setDataSets((prev) => ([...prev, res.data]));
        }
        hideModal();
    }

    return (
        <Modal show={true} onHide={hideModal} className="data-set-modal" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{dataSet?.id ? 'Data Set ' + dataSet.id : 'New Data Set'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="dataset-tabs">
                    <Tab eventKey="info" title="Info">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Data Type</Form.Label>
                                    <Form.Select value={dataType} onChange={e => setDataType(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="JSON">JSON</option>
                                        <option value="TABLE">TABLE</option>
                                        <option value="DESIGN">DESIGN</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Activity</Form.Label>
                                    <Form.Select value={activity} onChange={e => setActivity(e.target.value)}>
                                        <option value={null}></option>
                                        {allActivities.map(act => (
                                            <option value={act}>{act}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Data Source</Form.Label>
                                    <Form.Select value={dataSource} onChange={e => setDataSource(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="collect">Collect</option>
                                        <option value="function">Function</option>
                                        <option value="convertFromAnotherDataSet">Convert From Another DataSet</option>
                                        <option value="kahoot">Kahoot</option>
                                        <option value="ixl">IXL</option>
                                        <option value="khan">Khan</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="store">
                                    <Form.Label>Store</Form.Label>
                                    <Form.Select value={store} onChange={e => setStore(e.target.value)}>
                                        <option value={null}></option>
                                        {dataSet ? (
                                            dataSet.keywordsData.reduce((storeIDs, keyword) => {
                                                for (let i = 0; i < keyword.boomStores.length; i++) {
                                                    if (storeIDs.indexOf(keyword.boomStores[i]) === -1) {
                                                        storeIDs.push(keyword.boomStores[i]);
                                                    }
                                                }
                                                return storeIDs;
                                            }, []).map(sID => (
                                                <option value={sID} key={sID}>Store {sID}</option>
                                            ))
                                        ) : (
                                            stores.map(s => (
                                                <option value={s.id} key={s.id}>Store {s.id}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Quality</Form.Label>
                                    <Form.Select value={quality} onChange={e => setQuality(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="good">Good</option>
                                        <option value="medium">Medium</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Themes</Form.Label>
                                    <MultiValues value={seasons} setValue={val => setSeasons(val)} defaultChoices={allThemes.map(t => t.slug)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Audio</Form.Label>
                                    <Form.Select value={audioAvailable} onChange={e => setAudioAvailable(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Random Order</Form.Label>
                                    <Form.Select value={randomOrder} onChange={e => setRandomOrder(e.target.value)}>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Followed Data Set ID</Form.Label>
                                    <Form.Control value={followedDataSet} onChange={e => setFollowedDataSet(e.target.value)} />
                                    <Form.Text>Data Set used after this data set in product</Form.Text>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Story Name</Form.Label>
                                    <Form.Control value={storyName} onChange={e => setStoryName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col>
                                <h3>Current Keywords:</h3>
                                {keywords && (
                                    <ListGroup className="current-keywords">
                                        {keywords.map(keywordID => (
                                            <ListGroup.Item key={keywordID}>
                                                <span>{keywordID}. {dataSet?.keywordsData.find(k => k.id === keywordID)?.name}</span>
                                                <FontAwesomeIcon icon={faClose} onClick={() => {
                                                    setKeywords(prev => prev.filter(id => id !== keywordID));
                                                }} />
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                            </Col>
                            <Col>
                                <h3>Add New Keyword:</h3>
                                <AsyncSelectComponent
                                    searchUrl='resources/keywords/?name='
                                    onChange={selectedValue => {
                                        if (keywords.indexOf(selectedValue['id']) === -1) {
                                            setKeywords(prev => prev.concat([selectedValue['id']]));
                                        } else {
                                            setKeywords(prev => prev.filter(id => id !== selectedValue['id']));
                                        }
                                    }}
                                    labelField="label"
                                    value={{ value: '', label: '' }}
                                    setLoading={setLoading}
                                    loading={loading}
                                    clearSelection={() => {}}
                                    defaultData={[]}
                                    createable={true}
                                    onCreateOption={() => window.alert('dont create new keyword here')}
                                />
                            </Col>
                        </Row>

                    </Tab>
                    <Tab eventKey="link" title="Link">
                        <h3>Link DataSet to Template</h3>
                        {template && (
                            <a href={Constant.WORKSHEET_MAKER_URL + '?type=template&id=' + template + '&token=' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)} target="_blank">{template}</a>
                        )}
                        <AsyncSelectComponent
                            searchUrl='auto-content/templates/?page_size=1000&keyword='
                            onChange={selectedValue => {
                                setTemplate(selectedValue['id']);
                            }}
                            value={{ value: '', label: '' }}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => {}}
                            defaultData={[]}
                            createable={false}
                        />
                        <h3 className="mt-5">Link DataSet to Collection</h3>
                        {collection && (
                            <div>Collection: {collection}</div>
                        )}
                        <AsyncSelectComponent
                            searchUrl='auto-content/collections/?page_size=1000&keyword='
                            onChange={selectedValue => {
                                setCollection(selectedValue['id']);
                            }}
                            value={{ value: '', label: '' }}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => {}}
                            defaultData={[]}
                            createable={false}
                        />
                    </Tab>
                    <Tab eventKey="mapping" title="Mapping">
                        {dataSet?.topics && (
                            <DataSetMappingTable mapping={mapping} setMapping={setMapping} data={dataSet.topics} activity={activity} />
                        )}
                    </Tab>
                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={saveInfo}>
                    Save
                </Button>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
};

export default DataSetModal;