import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './styles.scss';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import Constant from '../../../Constant';
import UploadFile from '../../content-studio/upload-file';
import ImageWrapper from '../../common/image-wrapper';
import FilterByTheme from '../../../filters/theme';
import FilterByActivity from '../../../filters/activity';

const ActivityTemplateSetInfoModal = ({
    templateSetID = null,
    fetchTemplateSets,
    hideModal = () => { }
}) => {
    const [loading, setLoading] = useState(false);
    const [activity, setActivity] = useState(null);
    const [activityData, setActivityData] = useState(null);
    const [theme, setTheme] = useState(null);
    const [validated, setValidated] = useState(false);
    const [contentValidated, setContentValidated] = useState(false);
    const [storeGroup, setStoreGroup] = useState(null);
    const [active, setActive] = useState(true);
    const [templates, setTemplates] = useState(null);

    const [requirements, setRequirements] = useState([]);
    const [edittingTemplateValue, setEdittingTemplateValue] = useState(null);
    const [edittingRequirementSlug, setEdittingRequirementSlug] = useState(null);

    const [storeGroups, setStoreGroups] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        const fetchTemplateSetStoreGroups = async() => {
            const res = await getDataFromAPI('resources/store-groups/');
            setStoreGroups(res.data);
        }

        fetchTemplateSetStoreGroups();
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        const fetchTemplateSet = async() => {
            setLoading(true);
            const res = await getDataFromAPI('resources/activity-template-sets/' + templateSetID + '/');
            setActivity(res.data.activity);
            setActivityData(res.data.activityData);
            setTheme(res.data.theme);
            setValidated(res.data.validated);
            setContentValidated(res.data.contentValidated);
            setStoreGroup(res.data.storeGroup);
            setTemplates(res.data.templates);
            setActive(res.data.active);
            setLoading(false);
        }

        if (templateSetID) {
            fetchTemplateSet();
        }
    }, [templateSetID]);

    useEffect(() => {
        const fetchTemplateRequirements = async() => {
            const res = await getDataFromAPI('resources/activities/' + activity + '/');
            setRequirements(res.data['templateRequirements']);
        }

        if (activity) {
            fetchTemplateRequirements();
        }
    }, [activity]);

    const saveInfo = async() => {
        const data = { activity, theme, validated, storeGroup, templates, active };
        setLoading(true);
        if (templateSetID) {
            await updateDataAPI('PATCH', 'resources/activity-template-sets/' + templateSetID + '/', data);
        } else {
            await updateDataAPI('POST', 'resources/activity-template-sets/', data);
        }
        fetchTemplateSets();
        setLoading(false);
        hideModal();
    }

    const validateContent = async() => {
        setLoading(true);
        await saveInfo();
        await updateDataAPI('PATCH', 'resources/activity-template-sets/' + templateSetID + '/', {contentValidated: true});
        fetchTemplateSets();
        setLoading(false);
        hideModal();
    }

    const deleteSet = async() => {
        if (window.confirm('Are you sure you want to delete this set?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'resources/activity-template-sets/' + templateSetID + '/');
            fetchTemplateSets();
            setLoading(false);
            hideModal();
        }
    }

    const validateSet = async() => {
        if (window.confirm('Are you sure you want to validate this set?')) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/validate-template-set/', {templateSetID});
            fetchTemplateSets();
            setLoading(false);
            hideModal();
        }
    }

    const updateTemplates = () => {
        let newTemplates = templates;
        if (newTemplates === null) {
            newTemplates = {};
        }

        newTemplates[edittingRequirementSlug] = edittingTemplateValue;
        setTemplates(newTemplates);
        setEdittingRequirementSlug(null);
        setEdittingTemplateValue(null);
    }

    // generate puzzles for boom mystery picture unscramble puzzles game
    const generatePuzzles = async(req) => {
        setLoading(true);
        const imageUrl = templates[req.split('_')[0]];
        const res = await updateDataAPI('POST', 'resources/generate-boom-mystery-unscramble-puzzles/', {
            imageUrl,
        });
        setTemplates({...templates, [req]: JSON.stringify(res.data, null, 4)});
        setLoading(false);
    }

    const autoGenerateNameAndHint = async(req) => {
        setLoading(true);
        const imgSlug = req.split('_')[0];
        const imageUrl = templates[imgSlug];
        const res = await updateDataAPI('POST', 'resources/auto-generate-mystery-picture-name-and-hint/', {
            imageUrl,
        });
        setTemplates({
            ...templates,
            [imgSlug + '_name']: res.data['name'],
            [imgSlug + '_hint']: res.data['hint'],
            [imgSlug + '_directions']: res.data['directions'],
        });
        setLoading(false);
    }

    return (
        <Modal show={true} onHide={hideModal} className="activity-template-sets-modal" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{templateSetID ? 'Template Set ' + templateSetID : 'New Template Set'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="store-groups">
                            <Form.Label>Store Group</Form.Label>
                            <Form.Select value={storeGroup} onChange={e => setStoreGroup(e.target.value)}>
                                <option></option>
                                {storeGroups.map(group => (
                                    <option key={group['id']} value={group['id']}>Group {group['id']} ({group['label']})</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="activity">
                            <Form.Label>Activity</Form.Label>
                            <FilterByActivity filteringActivity={activity} setFilteringActivity={setActivity} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="theme">
                            <Form.Label>Theme</Form.Label>
                            <FilterByTheme filteringTheme={theme} setFilteringTheme={setTheme} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Check
                            id="activity-template-set-active"
                            label="Active"
                            value={active}
                            onChange={e => setActive(!active)}
                            checked={active}
                        />
                    </Col>
                </Row>

                {templateSetID && (
                    <Table striped bordered hover className="templates-table">
                        <tbody>
                            {requirements.filter(req => {
                                if (activityData['slug'].startsWith('boom_mystery')) {
                                    if (storeGroup === null && req['slug'].startsWith('image')) {
                                        return false;
                                    } else if (storeGroup !== null && !req['slug'].startsWith('image')) {
                                        return false;
                                    }
                                }

                                return true;
                            }).map((req, reqIndex) => (
                                <tr key={reqIndex}>
                                    <td>
                                        <div>{req['slug']}</div>
                                        <Form.Text>{req['description']}</Form.Text>
                                    </td>
                                    <td className="req-value-cell">
                                        {edittingRequirementSlug === req['slug'] ? (
                                            <div>
                                                <Form.Control value={edittingTemplateValue} onChange={e => setEdittingTemplateValue(e.target.value)} as="textarea" rows={3} />
                                                {req['slug'].startsWith('image') && (
                                                    <UploadFile
                                                        id={templateSetID}
                                                        setLoading={setLoading}
                                                        uploadUrl={'resources/upload-store-template-files/'}
                                                        allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                                        fieldName={'requirement_template_set_' + templateSetID + '_' + activity + '_' + theme + '_' + req['slug']}
                                                        uploadedFileName={null}
                                                        setUploadedFileName={data => {
                                                            setEdittingTemplateValue(data);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                {templates?.[req['slug']] && (
                                                    <a href={Constant.WORKSHEET_MAKER_URL + '?type=template&id=' + templates?.[req['slug']]} target="_blank">
                                                        {templates?.[req['slug']] && templates?.[req['slug']].length > 150 ? templates?.[req['slug']].substring(0, 150) + '...' : templates?.[req['slug']]}
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {typeof(templates?.[req['slug']]) === 'string' && templates?.[req['slug']].indexOf('https') > -1 && (
                                            <ImageWrapper src={templates?.[req['slug']]} />
                                        )}
                                    </td>
                                    {((!validated && !contentValidated) || userRoles.includes('admin')) && (
                                        <td>
                                            <div className="btns">
                                                {edittingRequirementSlug === req['slug'] ? (
                                                    <div className="btns">
                                                        <Button size="sm" variant="info" className="cancel-btn" onClick={() => {
                                                            setEdittingTemplateValue(null);
                                                            setEdittingRequirementSlug(null);
                                                        }}>
                                                            Cancel
                                                        </Button>
                                                        <Button size="sm" variant="success" onClick={updateTemplates}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div className="btns">
                                                        <Button size="sm" variant="info" onClick={() => {
                                                            if (templates?.[req['slug']]) {
                                                                setEdittingTemplateValue(templates?.[req['slug']]);
                                                            }
                                                            setEdittingRequirementSlug(req['slug']);
                                                        }}>
                                                            Edit
                                                        </Button>
                                                    </div>
                                                )}
                                                {activityData['slug'] === 'boom_mystery_unscramble' && req['slug'].startsWith('image') && req['slug'].endsWith('_puzzles') && (
                                                    <Button size="sm" onClick={() => generatePuzzles(req['slug'])}>Auto Generate Puzzles</Button>
                                                )}
                                                {activityData['slug'].startsWith('boom_mystery') && req['slug'].startsWith('image') && req['slug'].endsWith('_hint') && (
                                                    <Button size="sm" onClick={() => autoGenerateNameAndHint(req['slug'])}>Auto Generate Hint</Button>
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Modal.Body>
            <Modal.Footer>
                {templateSetID && (!validated || userRoles.includes('admin')) && (
                    <Button variant="danger" onClick={deleteSet}>
                        Delete
                    </Button>
                )}
                {templateSetID && userRoles.includes('admin') && (
                    <Button variant="success" onClick={validateSet}>
                        Validate
                    </Button>
                )}
                {templateSetID && (userRoles.includes('idea-manager') || userRoles.includes('admin')) && (
                    contentValidated ? (
                        <Button variant="success" disabled>
                            Content Validated
                        </Button>
                    ) : (
                        <Button variant="success" onClick={validateContent}>
                            Validate Content
                        </Button>
                    )
                )}
                {(!validated || userRoles.includes('admin')) && (
                    <Button variant="primary" onClick={saveInfo}>
                        Save
                    </Button>
                )}
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
};

export default ActivityTemplateSetInfoModal;