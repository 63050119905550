import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { Button, Form, Spinner, Table } from 'react-bootstrap';

import './styles.scss';

const OrphanKeywords = ({}) => {
    const [keywords, setKeywords] = useState([]);
    const [filteredCategoryID, setFilteredCategoryID] = useState('all');
    const [categories, setCategories] = useState([]);
    const [selectedKeywordIDs, setSelectedKeywordIDs] = useState([]);
    const [filteredKeywords, setFilteredKeywords] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchOrphanKeywords();
    }, []);

    useEffect(() => {
        setSelectedKeywordIDs([]);
        setFilteredKeywords(keywords.filter(keyword => {
            if (filteredCategoryID !== 'all' && keyword.parent != filteredCategoryID) {
                return false;
            }

            return true;
        }));
    }, [filteredCategoryID, keywords]);

    const fetchOrphanKeywords = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/fetch-orphan-keywords/?marketplace=boom');
        setKeywords(res.data);
        setFilteredKeywords(res.data);
        await fetchCategories(res.data);
        setLoading(false);
    }

    const fetchCategories = async(keywords) => {
        const res = await getDataFromAPI('resources/fetch-keyword-categories/');
        const categories = [];
        for (let i = 0; i < res.data.length; i++) {
            if (keywords.filter(keyword => keyword.parent === res.data[i].id).length > 0) {
                categories.push(res.data[i]);
            }
        }
        setCategories(categories);
    }

    const moveKeywordToStore = async(keyword) => {
        const targetStoreID = window.prompt('Move keyword to Store', '');
        if (targetStoreID) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/duplicate-keyword-to-other-store/', {
                keywordID: keyword.id,
                storeID: targetStoreID,
                marketplace: 'boom'
            });
            setKeywords(prev => prev.filter(k => k.id !== keyword.id));
            setLoading(false);
        }
    }

    const moveAllKeywordToStore = async(keyword) => {
        const targetStoreID = window.prompt('Move keyword to Store', '');
        if (targetStoreID) {
            setLoading(true);
            for (let i = 0; i < selectedKeywordIDs.length; i++) {
                await updateDataAPI('POST', 'resources/duplicate-keyword-to-other-store/', {
                    keywordID: selectedKeywordIDs[i],
                    storeID: targetStoreID,
                    marketplace: 'boom'
                });
            }
            setKeywords(prev => prev.filter(k => selectedKeywordIDs.indexOf(k.id) === -1));
            setLoading(false);
        }
    }

    return (
        <div className="orphan-keywords">
            <div className="filters">
                <Form.Select value={filteredCategoryID} onChange={e => setFilteredCategoryID(e.target.value)}>
                    <option value="all">all</option>
                    {categories.map(cat => (
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))}
                </Form.Select>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            <Form.Check
                                disabled={filteredCategoryID === 'all'}
                                type="checkbox"
                                onChange={() => {
                                    setSelectedKeywordIDs(filteredKeywords.map(k => k.id));
                                }}
                                checked={filteredKeywords.length === selectedKeywordIDs.length} />
                        </th>
                        <th>Keyword</th>
                        <th>
                            <Button size="sm" onClick={moveAllKeywordToStore}>Move all keywords to store</Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredKeywords.map((keyword, index) => (
                        <tr key={keyword.id}>
                            <td>
                                <div>{index + 1}</div>
                                <div>
                                    <Form.Check
                                        disabled={filteredCategoryID === 'all'}
                                        type="checkbox"
                                        onChange={() => {
                                            if (selectedKeywordIDs.indexOf(keyword.id) > -1) {
                                                setSelectedKeywordIDs(selectedKeywordIDs.filter(k => k !== keyword.id));
                                            } else {
                                                setSelectedKeywordIDs(selectedKeywordIDs.concat(keyword.id));
                                            }
                                        }}
                                        checked={selectedKeywordIDs.indexOf(keyword.id) > -1} />
                                </div>
                            </td>
                            <td>{keyword.id}. {keyword.name} - Grades {keyword.grades}</td>
                            <td>
                                <Button size="sm" onClick={() => moveKeywordToStore(keyword)}>Move to store</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    )
}

export default OrphanKeywords;