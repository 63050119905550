import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { getDataFromAPI } from '../../utils/query';

const FilterKeywordsByCategory = ({
    selectedCategory,
    setSelectedCategory = () => { },
}) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const res = await getDataFromAPI('resources/fetch-keyword-categories/')
        setCategories(res.data);
    }

    return (
        <Form.Select value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
            <option value={''}>Filter by category</option>
            {categories.sort((a, b) => a.name.localeCompare(b.name)).map(cat => (
                <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
        </Form.Select>
    )
};

export default FilterKeywordsByCategory;