import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { updateDataAPI } from '../../../../utils/query';

const UpdateJSONDataSetModal = ({
    dataSet,
    rows,
    setRows,
    setLoading,
    hideModal = () => { }
}) => {
    const [jsonData, setJSONData] = useState(null);

    useEffect(() => {
        const jsonData = [];
        for (let i = 0; i < rows.length; i++) {
            jsonData.push(rows[i].content);
        }
        setJSONData(JSON.stringify(jsonData, null, 4));
    }, []);

    const update = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/update-dataset-json/', {
            data: JSON.parse(jsonData),
            dataSetID: dataSet.id,
        });
        setRows(res.data);
        setLoading(false);
        hideModal();
    }

    return (
        <Modal show={true} onHide={hideModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Update DataSet {dataSet.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    as="textarea"
                    value={jsonData}
                    rows={20}
                    onChange={(e) => setJSONData(e.target.value)}
                    wrap='off'
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => update(jsonData)}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateJSONDataSetModal;