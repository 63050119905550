import { Button, Container, Form, ListGroup, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setThemes } from '../../filters/filtersSlice';
import { updateDataAPI } from '../../utils/query';
import { useState } from 'react';
import LoadingComponent from '../../utils/loading-component';

import './styles.scss';
import ThemeModal from '../../components/themes/theme-modal';

const ThemesPage = () => {
    const dispatch = useDispatch();

    const themes = useSelector(state => state.filters.themes);
    const userRoles = useSelector(state => state.permissions.userRoles);

    const [loading, setLoading] = useState(false);
    const [showThemeModal, setShowThemeModal] = useState(false);
    const [themeInModal, setThemeInModal] = useState(null);

    const toggleThemeField = async (theme, field) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'resources/tags/' + theme.id + '/', { [field]: !theme[field] });
        dispatch(setThemes(themes.map(s => {
            if (s.id === theme.id) {
                return { ...s, [field]: !theme[field] };
            }
            return s;
        })));
        setLoading(false);
    }

    return (
        <Container className="themes-page">
            <div className="themes-headers">
                <Button size="sm" onClick={() => {
                    setShowThemeModal(true);
                }}>New Theme</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Current Prior</th>
                        <th>Main Theme</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {themes.filter(theme => theme.parent === null).map(theme => (
                        <tr key={theme.id}>
                            <td>
                                <div onClick={() => {
                                    setThemeInModal(theme);
                                    setShowThemeModal(true);
                                }} className="theme-name">
                                    {theme.name}
                                </div>
                            </td>
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    id={'theme-' + theme.id + '-prior-checkbox'}
                                    onChange={() => toggleThemeField(theme, 'prior')}
                                    checked={theme.prior} />
                            </td>
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    id={'theme-' + theme.id + '-main-theme-checkbox'}
                                    onChange={() => toggleThemeField(theme, 'mainTheme')}
                                    checked={theme.mainTheme}
                                    disabled={!userRoles.includes('admin')} />
                            </td>
                            <td>
                                {themes.filter(t => t.parent === theme.id).map(subtheme => (
                                    <ListGroup key={subtheme.id}>
                                        <ListGroup.Item as="li" className="subtheme">
                                            <div onClick={() => {
                                                setThemeInModal(subtheme);
                                                setShowThemeModal(true);
                                            }} className="theme-name">{subtheme.name}</div>

                                            <div>
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Prior"
                                                    id={'theme-' + subtheme.id + '-prior-checkbox'}
                                                    onChange={() => toggleThemeField(subtheme, 'prior')}
                                                    checked={subtheme.prior} />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Main Theme"
                                                    id={'theme-' + subtheme.id + '-main-theme-checkbox'}
                                                    onChange={() => toggleThemeField(subtheme, 'mainTheme')}
                                                    checked={subtheme.mainTheme} />
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showThemeModal && (
                <ThemeModal
                    theme={themeInModal}
                    hideModal={(action, data) => {
                        if (action === 'save') {
                            dispatch(setThemes(themes.map(t => {
                                if (t.id === data.id) {
                                    return data;
                                }
                                return t;
                            })));
                        } else if (action === 'create') {
                            dispatch(setThemes([...themes, data]));
                        }
                        setShowThemeModal(false);
                        setThemeInModal(null);
                    }}
                />
            )}

            <LoadingComponent loading={loading} />
        </Container>
    )
}

export default ThemesPage;