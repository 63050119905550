import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import FontsAndFramesTab from './fonts-and-frames-tab';
import StoreThumbnailTemplateTab from './store-thumbnail-templates-tab';
import IconsTab from './icons-tab';
import StoreDescriptionTemplatesTab from './store-description-templates-tab';
import StoreTemplateFilesTab from './store-template-files-tab';
import StoreActivitiesTab from './store-activities-tab';
import { Spinner } from 'react-bootstrap';

export const generateOptions = (input = []) => {
    return [...input].map((item) => ({ ...item, label: item.name, value: item.id }))
};

const StoreConfigModal = ({
    storeID,
    fontFamilies = [],
    closeModal = () => { }
}) => {
    const [store, setStore] = useState(null);
    const [selectedFontFamilies, setSelectedFontFamilies] = useState([]);
    const [selectedFrames, setSelectedFrames] = useState([]);
    const [storeThumbnailPreviewTemplates, setStoreThumbnailPreviewTemplates] = useState([]);
    const [currentStoreIcons, setCurrentStoreIcons] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState('activities');

    useEffect(() => {
        const fetchStoreAssets = async () => {
            if (storeID) {
                setLoading(true);
                const url = `stores/${storeID}/?additionalFields=thumbnailTemplates,icons`;
                try {
                    const res = await getDataFromAPI(url);
                    const currentStoreFontFamilies = generateOptions(res.data.fontFamilies || []);
                    const currentStoreFrames = generateOptions(res.data.frames || []);
                    const currentStoreThumbnailTemplates = res.data?.thumbnailTemplates || [];
                    const storeIcons = res.data?.icons || [];

                    setStore(res.data);
                    setSelectedFontFamilies(currentStoreFontFamilies);
                    setSelectedFrames(currentStoreFrames);
                    setStoreThumbnailPreviewTemplates(currentStoreThumbnailTemplates);
                    setCurrentStoreIcons(storeIcons);
                } catch (err) {
                    console.error(`An error occured when fetching store: ${err.message}`)
                }
                setLoading(false);
            }
        }

        fetchStoreAssets();
    }, [storeID]);

    const hanldeChangeAssetsStore = async (assetName = 'font-families', selectedValues = []) => {
        const lastSelectedOption = selectedValues[selectedValues.length - 1];
        let lastTouchedOption, storeIDAfterAction;

        switch (assetName) {
            case 'font-families':
                lastTouchedOption = selectedValues.length > selectedFontFamilies.length
                    ? lastSelectedOption
                    : selectedFontFamilies[selectedFontFamilies.length - 1];
                storeIDAfterAction = selectedValues.length > selectedFontFamilies.length ? storeID : null;
                setSelectedFontFamilies(selectedValues);
                break;
            case 'frames':
                lastTouchedOption = selectedValues.length > selectedFrames.length
                    ? lastSelectedOption
                    : selectedFrames[selectedFrames.length - 1];
                storeIDAfterAction = selectedValues.length > selectedFrames.length ? storeID : null;
                setSelectedFrames(selectedValues);
                break;
            default:
                break;
        };

        let updatedStore = [...lastTouchedOption.store];

        if (storeIDAfterAction !== null) {
            updatedStore = [...updatedStore, storeIDAfterAction];
        } else {
            updatedStore = updatedStore.filter(assetStoreID => assetStoreID != storeID);
        };

        const url = `auto-content/${assetName}/${lastTouchedOption?.id}/`;

        try {
            await updateDataAPI('PATCH', url, { store: updatedStore });
        } catch (err) {
            console.error(`An error occurred when updating ${assetName}: ${err.message}`);
        }
    }

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    }

    return (
        <Modal className="store-config-modal" show={true} fullscreen={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Store {storeID} Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs fill activeKey={activeTab} onSelect={handleTabSelect}>
                    <Tab eventKey="activities" title="Activities">
                        {store && (
                            <StoreActivitiesTab
                                store={store}
                                setStore={setStore}
                                setLoading={setLoading}
                            />
                        )}
                    </Tab>
                    <Tab eventKey={"thumbnails"} title="Thumbnail templates">
                        <StoreThumbnailTemplateTab
                            storeID={storeID}
                            thumbnailPreviewTemplates={storeThumbnailPreviewTemplates}
                            setStoreThumbnailPreviewTemplates={setStoreThumbnailPreviewTemplates}
                        />
                    </Tab>
                    <Tab eventKey={"description_templates"} title="Description Templates">
                        <StoreDescriptionTemplatesTab
                            storeID={storeID}
                        />
                    </Tab>
                    <Tab eventKey={"fontsAndFrames"} title="Fonts and Frames">
                        <FontsAndFramesTab
                            hanldeChangeAssetsStore={hanldeChangeAssetsStore}
                            selectedFontFamilies={selectedFontFamilies}
                            selectedFrames={selectedFrames}
                            fontFamilies={fontFamilies}
                        />
                    </Tab>
                    <Tab eventKey={"icons"} title="Icons">
                        <IconsTab
                            storeID={storeID}
                            storeIcons={currentStoreIcons}
                            setCurrentStoreIcons={setCurrentStoreIcons}
                        />
                    </Tab>
                    <Tab eventKey={"template_files"} title="Template Files">
                        <StoreTemplateFilesTab
                            storeID={storeID}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
};

export default StoreConfigModal;