import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import axios from 'axios';
import Constant from './Constant';
import Header from './components/header';
import { hasPermission } from './utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { checkStaffHasAccessToProductIdea } from './utils/auth';
import { getDataFromAPI } from './utils/query';
import { setActivities, setThemes, setStores } from './filters/filtersSlice';
import { setUserRoles } from './redux/permissionsSlice';

const AuthRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const { routePermissions } = useSelector((state) => state.permissions);

    useEffect(() => {
        const checkStaffAccessRight = async () => {
            const urlSearchParams = (new URLSearchParams(window.location.search));
            if (urlSearchParams.has('customID')) {
                const customID = urlSearchParams.get('customID');
                const hasAccess = await checkStaffHasAccessToProductIdea(customID);
                if (!hasAccess) {
                    window.location.href = '/';
                }
            }
        };

        checkStaffAccessRight();
    }, []);

    useEffect(() => {
        fetchStores();
        fetchThemes();
        fetchActivities();
    }, []);

    const fetchStores = async () => {
        const res = await getDataFromAPI('stores/?active=true');
        dispatch(setStores(res.data));
    }

    const fetchThemes = async () => {
        const res = await getDataFromAPI('resources/tags/?types=season');
        dispatch(setThemes(res.data));
    }

    const fetchActivities = async() => {
        const res = await getDataFromAPI('resources/activities/');
        dispatch(setActivities(res.data));
    }

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const token = localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN);
            const email = localStorage.getItem(Constant.LOCAL_STORAGE_USER_EMAIL);
            if (token) {
                try {
                    const res = await axios.post(Constant.API_URL + 'product-dev/check-user-token/', { token, email });
                    const roles = res.data['roles'];
                    localStorage.setItem(Constant.LOCAL_STORAGE_ROLES, JSON.stringify(roles));
                    localStorage.setItem(Constant.LOCAL_STORAGE_USER_NAME, res.data['name']);
                    if (routePermissions[rest['path']] === undefined) {
                        return;
                    }

                    if (!hasPermission(roles, routePermissions[rest['path']])) {
                        window.location.href = '/';
                    }

                    dispatch(setUserRoles(roles));
                    setLoading(false);
                } catch (e) {
                    window.location.href = '/login';
                    localStorage.removeItem(Constant.LOCAL_STORAGE_TOKEN);
                    localStorage.removeItem(Constant.LOCAL_STORAGE_ROLES);
                    localStorage.removeItem(Constant.LOCAL_STORAGE_USER_EMAIL);
                    localStorage.removeItem(Constant.LOCAL_STORAGE_STAFF_ID);
                    localStorage.removeItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN);
                    localStorage.removeItem(Constant.LOCAL_STORAGE_ADMIN_EMAIL);
                    setLoading(false);
                }
            } else {
                window.location.href = '/login';
                setLoading(false);
            }
        };

        checkUserLoggedIn();
    }, [routePermissions]);

    if (loading || Object.keys(routePermissions).length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                <div>
                    <Header />
                    <Component {...props} />
                </div>
            }
        />
    );
};

export default AuthRoute;