import { useEffect, useState } from 'react';
import { Badge, Container, Form, ListGroup, Spinner, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getDataFromAPI } from '../../utils/query';

import './styles.scss';
import StoreConfigModal from '../../components/idea-list/store-config-modal';
import TicketsErrorModal from '../../components/store-templates-config/ticket-errors-modal';
import IdeasTicketsByActivityModal from '../../components/store-templates-config/ideas-tickets-by-activity-modal';
import FilterByStoreV0 from '../../filters/store';

const StoreTemplatesConfigPage = () => {
    const stores = useSelector(state => state.filters.stores);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const themes = useSelector(state => state.filters.themes);

    const [requirements, setRequirements] = useState();
    const [storeInAssetsManagementModal, setStoreInAssetsManagementModal] = useState(null);
    const [storeTickets, setStoreTickets] = useState({});
    const [ticketErrorsInfoInModal, setTicketErrorsInfoInModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activityInIdeasTicketsByActivityModal, setActivityInIdeasTicketsByActivityModal] = useState(null);
    const [filteredPriorSeason, setFilteredPriorSeason] = useState('prior');

    useEffect(() => {
        const fetchAllStoreTemplateRequirements = async() => {
            setLoading(true);
            const res = await getDataFromAPI('fetch-all-store-template-requirements/');
            setRequirements(res.data);
            setLoading(false);
        }

        fetchAllStoreTemplateRequirements();
    }, []);

    const getStoreStatusBySeason = (store, season) => {
        if (store.boomActivities === null) {
            return;
        }
        const storeRequirements = requirements[store.id]['requirements'];
        const storeConfig = requirements[store.id]['config'];
        return (
            <ListGroup>
                {Object.keys(store.boomActivities).filter(activity => storeRequirements?.[activity]?.[season] && (season === 'common' || (season !== 'common' && store || store.hasSeasonalProducts))).map(activity => (
                    <ListGroup.Item key={activity} className="store-requirement-list-item">
                        <span onClick={() => setStoreInAssetsManagementModal(store.id)}>{activity}</span>
                        <div>
                            <Badge bg={storeConfig?.[activity]?.[season] && storeConfig?.[activity]?.[season]['ready'] && Object.keys(storeConfig?.[activity]?.[season]).filter(key => key !== 'ready').length >= storeRequirements[activity][season].length ? 'success' : 'danger'}>
                                {storeConfig?.[activity]?.[season] ? (
                                    <span>
                                        {Object.keys(storeConfig?.[activity]?.[season]).filter(key => key !== 'ready').length}
                                    </span>
                                ) : (
                                    <span>0</span>
                                )}
                                /
                                <span>{storeRequirements[activity][season].length}</span>
                            </Badge>

                            <div className="show-details-popup" onClick={() => {
                                setActivityInIdeasTicketsByActivityModal({
                                    storeID: store.id,
                                    activity,
                                    season,
                                });
                            }}>
                                Show Details
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    }

    return (
        <Container fluid className="store-templates-config-page">
            <div className="filters">
                <FilterByStoreV0 hasAllOption={true} marketplace="boom" />
                <Form.Select value={filteredPriorSeason} onChange={e => setFilteredPriorSeason(e.target.value)}>
                    <option value="all">All seasons</option>
                    <option value="prior">Prior seasons</option>
                </Form.Select>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Store</th>
                        <th>Common</th>
                        {themes.filter(season => {
                            if (filteredPriorSeason === 'prior' && !season.prior) {
                                return false;
                            }

                            return true;
                        }).map(season => (
                            <th key={season.id}>
                                {season.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {stores.filter(s => {
                        if (selectedStoreID !== 'all' && s.id !== selectedStoreID) {
                            return false;
                        }

                        if (!s.active) {
                            return false;
                        }

                        if (s.marketplace !== 'boom') {
                            return false;
                        }

                        return true;
                    }).map(store => (
                        <tr key={store.id}>
                            <td>
                                <a href={'/ideas?selectedStoreID=' + store.id} target="_blank">{store.id}</a>
                                <div className="store-meta-info">{store.boomSubjects}</div>
                                <div className="store-meta-info">{store.boomClass}</div>
                            </td>
                            {requirements && (
                                <>
                                    <td>
                                        {getStoreStatusBySeason(store, 'common')}
                                    </td>
                                    {store.hasSeasonalProducts && (
                                        themes.filter(season => {
                                            if (filteredPriorSeason === 'prior' && !season.prior) {
                                                return false;
                                            }

                                            return true;
                                        }).map(season => (
                                            <td key={season.id}>
                                                {getStoreStatusBySeason(store, season.name.toLowerCase().replace(/ /g, '_'))}
                                            </td>
                                        ))
                                    )}
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>

            {storeInAssetsManagementModal !== null && (
                <StoreConfigModal
                    storeID={storeInAssetsManagementModal}
                    fontFamilies={[]}
                    closeModal={() => setStoreInAssetsManagementModal(null)}
                />
            )}

            {ticketErrorsInfoInModal !== null && (
                <TicketsErrorModal
                    storeID={ticketErrorsInfoInModal['storeID']}
                    activity={ticketErrorsInfoInModal['activity']}
                    tickets={ticketErrorsInfoInModal['tickets']}
                    updateTicketError={(storeID, ticketID, done, err) => {
                        setStoreTickets({
                            ...storeTickets,
                            [storeID]: storeTickets[storeID].map(ticket => {
                                if (ticket.id === ticketID) {
                                    return {...ticket, genError: err, done: done};
                                }
                                return ticket;
                            })});
                        setTicketErrorsInfoInModal({...ticketErrorsInfoInModal, tickets: storeTickets[storeID].filter(ticket => ticket.ideaType === ticketErrorsInfoInModal['activity'])});
                    }}
                    hideModal={() => setTicketErrorsInfoInModal(null)}
                />
            )}

            {activityInIdeasTicketsByActivityModal !== null && (
                <IdeasTicketsByActivityModal
                    activity={activityInIdeasTicketsByActivityModal}
                    hideModal={() => setActivityInIdeasTicketsByActivityModal(null)}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default StoreTemplatesConfigPage;