import { useEffect, useState } from 'react';
import { Badge, Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';

import './styles.scss';
import KeywordInfoModal from '../../components/product-map/keyword-info-modal';
import ProductMapIdeasCell from '../../components/product-map/ideas-cell';
import Constant from '../../Constant';
import PagePagination from '../../components/auto-content/templates-management/page-pagination';
import FilterKeywordsByCategory from '../../filters/keywords-by-category';

const ProductMapPage = () => {
    const [keywords, setKeywords] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showKeywordInfoModal, setShowKeywordInfoModal] = useState(false);
    const [keywordInInfoModal, setKeywordInInfoModal] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [displayingIdeaList, setDisplayingIdeaList] = useState(null);

    const keywordPageSize = 30;
    const [currentKeywordPage, setCurrentKeywordPage] = useState(1);
    const [totalKeywordPages, setTotalKeywordPages] = useState(1);
    const [lastSearchCategory, setLastSearchCategory] = useState(null);
    const [lastSearchKeyword, setLastSearchKeyword] = useState(null);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('keyword');
        setSearchKeyword(id);
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        fetchKeywords();
    }, []);

    const fetchKeywords = async() => {
        setLoading(true);
        let url = `resources/keywords/?additionalFields=ideas,dataSets,hasChildren,nbTicketsError&page_size=${keywordPageSize}`;
        let page = currentKeywordPage;
        if (searchKeyword !== lastSearchKeyword || selectedCategory !== lastSearchCategory) {
            page = 1;
            setCurrentKeywordPage(1);
        }
        if (searchKeyword !== null && searchKeyword.length > 0) {
            setLastSearchCategory(searchKeyword);
            url += '&name=' + searchKeyword;
        }

        if (selectedCategory) {
            setLastSearchCategory(selectedCategory);
            url += '&keywordParentID=' + selectedCategory;
        }

        url += '&page=' + page;
        const res = await getDataFromAPI(url);

        setTotalKeywordPages(Math.ceil(res.data.count / keywordPageSize))
        setKeywords(res.data.results);
        setLoading(false);
    }

    const refreshKeyword = async(keywordID) => {
        setLoading(true);
        const res = await getDataFromAPI('resources/keywords/' + keywordID + '/?additionalFields=ideas,dataSets,nbTicketsError');
        setKeywords(keywords.map(keyword => {
            if (keyword.id === keywordID) {
                return res.data;
            }
            return keyword;
        }));
        setLoading(false);
    }

    const handlePageChange = (pageIndex) => {
        setCurrentKeywordPage(pageIndex);
        fetchKeywords(pageIndex);
    }

    return (
        <Container className="product-map-page" fluid>
            <h2>Product Map</h2>

            <div className="filters">
                <div>
                    <FilterKeywordsByCategory
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
                    <Form.Control value={searchKeyword} onChange={e => setSearchKeyword(e.target.value)} placeholder="search keyword" />
                    <Button onClick={fetchKeywords}>Search</Button>
                </div>

                <div>
                    <Button onClick={() => setShowKeywordInfoModal(true)}>New Keyword</Button>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Keyword</th>
                        <th>Data Sets</th>
                        <th>Boom</th>
                        <th>TPT</th>
                    </tr>
                </thead>
                <tbody>
                    {keywords.map(keyword => (
                        <tr key={keyword.id}>
                            <td>
                                <div>{keyword.id}</div>
                                <div className="refresh-btn" onClick={() => refreshKeyword(keyword.id)}>
                                    refresh
                                </div>
                            </td>
                            <td className="keyword-name-cell">
                                <div className="keyword-name" onClick={() => {
                                    setKeywordInInfoModal(keyword);
                                    setShowKeywordInfoModal(true);
                                }}>
                                    {selectedCategory ? (
                                        <span>{keyword.name} - {keyword.grades}</span>
                                    ) : (
                                        <span>
                                            {keyword.parentName ? keyword.parentName + ' > ' : ''} {keyword.name} - {keyword.grades}
                                        </span>
                                    )}
                                </div>

                                {(keyword.subKeywords === null || keyword.subjectTPTs === null || keyword.subjectBooms === null) && (
                                    <Badge bg="danger">missing info</Badge>
                                )}
                                {keyword.nbTicketsError > 0 && (
                                    <Badge bg="danger">{keyword.nbTicketsError} tickets error</Badge>
                                )}
                            </td>
                            <td>
                                <Table striped bordered hover className="keyword-dataSets">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Data Type</th>
                                            <th>Activity</th>
                                            <th>Source</th>
                                            <th>Seasons</th>
                                            <th>Quality</th>
                                            <th>Audio</th>
                                            <th>Store</th>
                                            <th>Validated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {keyword.dataSets.map(dataSet => (
                                            <tr key={dataSet.id} className="dataSet">
                                                <td>
                                                    <a href={'/data-set/?id=' + dataSet.id} target="_blank">
                                                        {dataSet.id}
                                                    </a>
                                                </td>
                                                <td>{dataSet.dataType}</td>
                                                <td>{dataSet.activity}</td>
                                                <td>{dataSet.dataSource}</td>
                                                <td>{dataSet.seasons}</td>
                                                <td>{dataSet.quality}</td>
                                                <td>{dataSet.audioAvailable ?? ''}</td>
                                                <td>{dataSet.store}</td>
                                                <td>
                                                    {dataSet.validated && (
                                                        <div>{dataSet.nbQuestionsValidated} rows</div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={4}>
                                                <div>
                                                    <strong>{keyword.dataSets.filter(dataSet => dataSet.dataType === 'JSON' && dataSet.validated).reduce((total, dataSet) => total + dataSet.totalRows, 0)} good questions</strong>
                                                </div>
                                                <div>
                                                    {keyword.dataSets.filter(dataSet => dataSet.dataType !== 'JSON' && dataSet.validated).reduce((total, dataSet) => total + dataSet.nbQuestionsValidated, 0)} medium questions
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                            <td>
                                {(displayingIdeaList && displayingIdeaList['keyword'] === keyword.id && displayingIdeaList['marketplace'] === 'boom') ? (
                                    <ProductMapIdeasCell
                                        ideas={keyword.ideas.filter(idea => idea.marketplace === 'boom')}
                                        userRoles={userRoles}
                                        setLoading={setLoading}
                                        refreshKeyword={() => refreshKeyword(keyword.id)} />
                                ) : (
                                    <div className="nb-ideas" onClick={() => setDisplayingIdeaList({keyword: keyword.id, marketplace: 'boom'})}>
                                        {keyword.ideas.filter(idea => idea.marketplace === 'boom').length} ideas
                                    </div>
                                )}
                            </td>
                            <td>
                                {(displayingIdeaList && displayingIdeaList['keyword'] === keyword.id && displayingIdeaList['marketplace'] === 'tpt') ? (
                                    <ProductMapIdeasCell
                                        ideas={keyword.ideas.filter(idea => idea.marketplace === 'tpt')}
                                        userRoles={userRoles}
                                        setLoading={setLoading}
                                        refreshKeyword={() => refreshKeyword(keyword.id)} />
                                ) : (
                                    <div className="nb-ideas" onClick={() => setDisplayingIdeaList({keyword: keyword.id, marketplace: 'tpt'})}>
                                        {keyword.ideas.filter(idea => idea.marketplace === 'tpt').length} ideas
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <PagePagination
                currentPage={currentKeywordPage}
                totalPages={totalKeywordPages}
                handlePageChange={handlePageChange}
            />

            {showKeywordInfoModal && (
                <KeywordInfoModal
                    keywordID={keywordInInfoModal?.id}
                    setLoading={setLoading}
                    updateKeyword={async(keyword) => {
                        if (keywords.find(k => k.id === keyword.id)) {
                            setKeywords(keywords.map(k => {
                                if (k.id === keyword.id) {
                                    return keyword;
                                }

                                return k;
                            }))
                        } else {
                            setKeywords(keywords.concat(keyword));
                        }
                    }}
                    removeKeyword={(keywordID) => {
                        setKeywords(prev => prev.filter(k => k.id !== keywordID));
                    }}
                    hideModal={() => {
                        setShowKeywordInfoModal(false);
                        setKeywordInInfoModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ProductMapPage;