import Select from 'react-select';
import { useSelector } from 'react-redux';

const FilterByStore = ({ filteringStore, setFilteringStore, marketplace='all' }) => {
    const stores = useSelector(state => state.filters.stores);

    const options = [
        { value: 'all', label: 'All' },
        ...stores.filter(store => {
            if (marketplace !== 'all' && store.marketplace !== marketplace) {
                return false;
            }

            return true;
        }).map(store => ({ value: store.id, label: store.name }))
    ];

    const handleChange = (selectedOption) => {
        setFilteringStore(selectedOption['value']);
    }

    const selectedOption = options.find(store => store.value === filteringStore) || options[0];

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Select a store"
        />
    );
};

export default FilterByStore;
