import { useState } from 'react';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import KeywordInfoModal from '../../product-map/keyword-info-modal';

import './styles.scss';

const TicketsErrorModal = ({storeID, activity, tickets, hideModal, updateTicketError}) => {
    const [keywordInModal, setKeywordInModal] = useState(null);
    const [loading, setLoading] = useState(false);

    const showKeywordModal = async(keywordID) => {
        const res = await getDataFromAPI('resources/keywords/' + keywordID + '/');
        setKeywordInModal(res.data);
    }

    const runTicket = async(ticketID) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'product-dev/gen-data-ticket/', {
            ticketID
        });
        if (res.data['success']) {
            window.alert('success');
            updateTicketError(storeID, ticketID, true, null);
        } else {
            window.alert('error');
            updateTicketError(storeID, ticketID, false, res.data['genError']);
        }
        setLoading(false);
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="tickets-error-modal"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Store {storeID} - Activity {activity} - Ticket Errors
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Keyword</th>
                            <th>Error</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets.map(ticket => (
                            <tr key={ticket.id}>
                                <td>{ticket.id}</td>
                                <td>
                                    <span className="keyword-name" onClick={() => showKeywordModal(ticket.keyword)}>
                                        {ticket.keywordName}
                                    </span>
                                </td>
                                <td>{ticket.genError}</td>
                                <td>
                                    <Button size="sm" onClick={() => runTicket(ticket.id)}>Re-run</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>

            {keywordInModal !== null && (
                <KeywordInfoModal
                    keywordID={keywordInModal.id}
                    setLoading={() => {}}
                    hideModal={() => setKeywordInModal(null)}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default TicketsErrorModal;