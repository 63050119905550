import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { updateDataAPI } from '../../../utils/query';

const ThemeModal = ({ theme = null, hideModal }) => {
    const allThemes = useSelector(state => state.filters.themes);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(theme?.name);
    const [slug, setSlug] = useState(theme?.slug);
    const [parent, setParent] = useState(theme?.parent);

    const save = async() => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'resources/tags/' + theme.id + '/', {name, slug, parent});
        hideModal('save', res.data);
        setLoading(false);
    }

    const create = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/tags/', {name, slug, parent});
        setLoading(false);
        hideModal('create', res.data);
    }

    return (
        <Modal size="lg" show={true} onHide={hideModal} className="theme-modal">
            <Modal.Header closeButton>
                <Modal.Title>Theme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={name} onChange={e => setName(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="label">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control value={slug} onChange={e => setSlug(e.target.value)} />
                            <Form.Text>Lowercase, replace space to _</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Parent</Form.Label>
                            <Form.Select value={parent} onChange={e => setParent(e.target.value)}>
                                <option value={null}></option>
                                {allThemes.filter(t => t.parent === null).map(t => (
                                    <option key={t.id} value={t.id}>{t.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {theme ? (
                    <Button onClick={save}>Save</Button>
                ) : (
                    <Button onClick={create}>Create</Button>
                )}
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default ThemeModal;