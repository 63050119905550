import { Badge, ListGroup } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';
import KeywordInfoModal from '../../product-map/keyword-info-modal';
import { useState } from 'react';

import './styles.scss';

const KeywordList = ({keywords, setLoading, removeKeywordFromStore, duplicateKeywordToOtherStore, refreshStore}) => {
    const [keywordInModal, setKeywordInModal] = useState(null);

    const showKeywordModal = async(keywordID) => {
        setLoading(true);
        const res = await getDataFromAPI('resources/keywords/' + keywordID + '/');
        setKeywordInModal(res.data);
        setLoading(false);
    }

    const navigateToKeyword = async(dir) => {
        for (let i = 0; i < keywords['keywords'].length; i++) {
            if (dir === 'next' && keywords['keywords'][i].id === keywordInModal.id && i < keywords['keywords'].length - 1) {
                setKeywordInModal(null);
                setTimeout(() => {
                    showKeywordModal(keywords['keywords'][i + 1].id);
                }, 0);
                break;
            } else if (dir === 'prev' && keywords['keywords'][i].id === keywordInModal.id && i > 0) {
                setKeywordInModal(null);
                setTimeout(() => {
                    showKeywordModal(keywords['keywords'][i - 1].id);
                }, 0);
                break;
            }
        }
    }

    return (
        <ListGroup className="keyword-list">
            {keywords.map(keyword => (
                <ListGroup.Item className={keyword.nbIdeas > 0 ? 'success' : 'secondary'}>
                    <div onClick={() => showKeywordModal(keyword.id)}>
                        {keyword.id}. {keyword.name} - {keyword.grades}
                    </div>
                    <div className="meta-info">
                        {keyword.nbIdeas > 0 && (
                            <Badge bg="success">
                                {keyword.nbIdeas} ideas
                            </Badge>
                        )}

                        <Badge bg="secondary">{keyword.nbDataSets > 0 ? keyword.nbValidatedDataSets + '/' + keyword.nbDataSets + ' data sets' : ''}</Badge>
                        {keyword.nbIdeas === 0 && (
                            keyword.nbQuestions >= 20 && keyword.nbIdeas === 0 ? (
                                <Badge bg="warning">{keyword.nbValidatedQuestions}/{keyword.nbQuestions} questions</Badge>
                            ) : (
                                keyword.nbQuestions > 0 && (
                                    <Badge bg="secondary">{keyword.nbValidatedQuestions}/{keyword.nbQuestions} questions</Badge>
                                )
                            )
                        )}
                        <div>{keyword.nbPendingTickets > 0 ? keyword.nbPendingTickets + ' pending tickets' : ''}</div>
                        {keyword.nbPendingTicketErrors > 0 && (
                            <Badge bg="danger">{keyword.nbPendingTicketErrors} ticket errors</Badge>
                        )}
                    </div>
                    {/* <div className="keyword-btns">
                        {keyword.nbIdeas === 0 && (
                            <Button variant="danger" size="sm" onClick={() => removeKeywordFromStore(keyword)}>Remove Keyword from store</Button>
                        )}
                        <Button size="sm" onClick={() => duplicateKeywordToOtherStore(keyword)}>Duplicate Keyword to other store</Button>
                    </div> */}
                </ListGroup.Item>
            ))}

            {keywordInModal !== null && (
                <KeywordInfoModal
                    keywordID={keywordInModal.id}
                    setLoading={setLoading}
                    hideModal={() => setKeywordInModal(null)}
                    navigation={true}
                    navigateToKeyword={navigateToKeyword}
                    removeKeyword={refreshStore}
                />
            )}
        </ListGroup>
    )
}

export default KeywordList;