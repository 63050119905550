import { Button, Container, Form, Spinner } from 'react-bootstrap';
import FilterBySearch from '../../filters/search';
import FilterByStaff from '../../filters/staff';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromAPI } from '../../utils/query';

import './styles.scss';
import Notification from '../../utils/notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand, faClose } from '@fortawesome/free-solid-svg-icons';
import Constant from '../../Constant';
import { setSelectedStoreID } from '../../filters/filtersSlice';
import TaskStatistic from '../../components/task-management/task-statistic';
import { hasPermission, logout } from '../../utils/auth';
import WidgetBot from '@widgetbot/react-embed';
import TaskBoard from '../../components/task-management/task-board';
import ManualTaskModal from '../../components/task-management/manual-task-modal';
import FilterByTag from '../../filters/tag';
import FilterByTaskFlow from '../../filters/task-flow';
import FilterByStoreV0 from '../../filters/store';

const ADDITION_FIELDS = 'additionalFields=assignedStaffs,history,taskPriority,advanced,thumbnail,tags,stepRequirements';

const TaskManagementPage = () => {
    const dispatch = useDispatch();

    const selectedTaskFlow = useSelector((state) => state.filters.selectedTaskFlow);
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);
    const selectedStaff = useSelector((state) => state.filters.selectedStaff);
    const selectedTag = useSelector((state) => state.filters.selectedTag);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [tasks, setTasks] = useState([]);
    const [taskFlowSteps, setTaskFlowSteps] = useState([]);
    const [notification, setNotification] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stepStats, setStepStats] = useState({});
    const [selectedTaskPriority, setSelectedTaskPriority] = useState('all');
    const [filterByAdvanced, setFilterByAdvanced] = useState(false);
    const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
    const [activeChatChannelID, setActiveChatChannelID] = useState(null);
    const [showTaskWidget, setShowTaskWidget] = useState(null);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));

        const staffId = localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID);
        const token = localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN);
        if (!staffId && token) {
            logout();
        }
    }, []);

    useEffect(() => {
        if (selectedTaskFlow) {
            fetchTasks();
            fetchTaskFlowSteps();

            if (selectedTaskFlow === Constant.MANUAL_CATEGORY_ID) {
                dispatch(setSelectedStoreID('all'));
            }
        }
    }, [selectedTaskFlow, selectedStoreID]);

    useEffect(() => {
        updateStepStats(tasks);
    }, [selectedStaff]);

    const fetchTasks = async () => {
        setLoading(true);
        let url = 'product-dev/fetch-in-progress-tasks/?inprogress=true&category=' + selectedTaskFlow + '&' + ADDITION_FIELDS;
        let storeID = selectedStoreID ?? 'all';
        if (selectedTaskFlow !== Constant.MANUAL_CATEGORY_ID) {
            url += '&store=' + storeID;
        }
        const res = await getDataFromAPI(url);
        setTasks(res.data);
        updateStepStats(res.data);
        setLoading(false);
    }

    const fetchTask = async (taskID) => {
        setLoading(true);
        let url = 'product-dev/tasks/' + taskID + '/?inprogress=true&category=' + selectedTaskFlow + '&' + ADDITION_FIELDS;
        const res = await getDataFromAPI(url);
        setTasks(tasks.map(t => {
            if (t.id === taskID) {
                return res.data;
            }
            return t;
        }));
        setLoading(false);
    }

    const updateStepStats = (tasks) => {
        const stepStats = {};
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            if (stepStats[task.step] === undefined) {
                stepStats[task.step] = {
                    totalTasks: 0,
                }
            }

            stepStats[task.step]['totalTasks']++;
        }
        setStepStats(stepStats);
    }

    const fetchTaskFlowSteps = async () => {
        const res = await getDataFromAPI('product-dev/task-flow-steps/?category=' + selectedTaskFlow);
        setTaskFlowSteps(res.data);
    }

    const getFilteredTasks = () => {
        return tasks.filter(task => {
            if (searchKeyword !== '' && task.customID.indexOf(searchKeyword) === -1) {
                return false;
            }
            if (selectedStaff !== null && task.assignedStaffs.find(assignee => assignee.id === selectedStaff) === undefined) {
                return false;
            }
            if (selectedTaskPriority !== 'all' && task.priority !== selectedTaskPriority) {
                return false;
            }
            if (filterByAdvanced && !task.advanced) {
                return false;
            }
            if (selectedTag !== null && task.tags.filter(tag => tag.id === selectedTag).length === 0) {
                return false;
            }
            if (selectedStoreID !== 'all' && selectedStoreID !== null && task.store !== selectedStoreID) {
                return false;
            }
            return true;
        });
    }

    return (
        <Container className="task-management-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByTaskFlow />
                        {selectedTaskFlow !== Constant.MANUAL_CATEGORY_ID && (
                            <FilterBySearch placeholder="search by code" />
                        )}
                        <FilterByTag type="product" />
                    </div>
                    <div>
                        {hasPermission(userRoles, activityPermissions['TASK_MANAGER_FILTER_BY_STAFF']) && (
                            <FilterByStaff roles="designer,product-collector,product-informator" />
                        )}
                        {selectedTaskFlow !== null && selectedTaskFlow !== Constant.MANUAL_CATEGORY_ID && (
                            <FilterByStoreV0 hasAllOption={true} showMetaLink={false} showStoreDescription={false} />
                        )}
                    </div>
                </div>
                <div className="filter-right">
                    <div>
                        {selectedTaskFlow === Constant.MANUAL_CATEGORY_ID && (
                            <Button className="new-task-btn" onClick={() => setShowCreateTaskModal(true)}>New Task</Button>
                        )}
                        <div className="filter-by-prior">
                            <select className="form-select" onChange={e => setSelectedTaskPriority(e.target.value)}>
                                <option value="all">All Priority</option>
                                <option value="low">Low</option>
                                <option value="normal">Normal</option>
                                <option value="high">High</option>
                            </select>
                        </div>
                        <Form>
                            {tasks.filter(task => task.advanced).length > 0 && (
                                <div className="filter-by-advanced">
                                    <FontAwesomeIcon icon={faHand} />
                                    <Form.Check type="checkbox" label={tasks.filter(task => task.advanced).length + ' ADVANCED task(s)'}
                                        checked={filterByAdvanced} id="filter-by-advanced"
                                        onChange={e => setFilterByAdvanced(!filterByAdvanced)} />
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="board" style={{ width: taskFlowSteps.length * 310 }}>
                <TaskBoard taskFlowSteps={taskFlowSteps}
                    tasks={getFilteredTasks()}
                    stepStats={stepStats}
                    setTasks={setTasks}
                    setLoading={setLoading}
                    fetchTask={fetchTask}
                    fetchTasks={fetchTasks}
                    setNotification={setNotification}
                    setActiveChatChannelID={setActiveChatChannelID}
                    ADDITION_FIELDS={ADDITION_FIELDS}
                />
            </div>

            {userRoles.indexOf('admin') !== -1 && (
                <TaskStatistic
                    tasks={tasks}
                    showTaskWidget={showTaskWidget}
                    setShowTaskWidget={setShowTaskWidget}
                />
            )}

            {activeChatChannelID && (
                <div className="task-chat-popup">
                    <WidgetBot
                        server="716349344403423402"
                        channel={activeChatChannelID}
                        width={400}
                        height={600}
                    />
                    <span className="task-chat-trigger" onClick={() => setActiveChatChannelID(null)}>
                        <FontAwesomeIcon icon={faClose} />
                    </span>
                </div>
            )}

            {showCreateTaskModal && (
                <ManualTaskModal
                    taskFlowSteps={taskFlowSteps}
                    closeModal={action => {
                        if (action === 'create') {
                            fetchTasks();
                            setNotification('Create successfully');
                        }
                        setShowCreateTaskModal(false);
                    }} />
            )}

            {notification && (
                <Notification title={notification} closeNotification={() => setNotification(null)} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container >
    )
}

export default TaskManagementPage;