import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import './styles.scss';
import { updateDataAPI } from '../../../utils/query';
import FilterByTheme from '../../../filters/theme';
import FilterByActivity from '../../../filters/activity';

const GenDataTicketModal = ({ticket, keyword, setTickets, setLoading, hideModal}) => {
    const [activity, setActivity] = useState(ticket?.activity);
    const [theme, setTheme] = useState(ticket?.theme);
    const [quality, setQuality] = useState(ticket?.quality);
    const [store, setStore] = useState(ticket?.store);

    const save = async() => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=dataSetsData,ideaData', {
            activity,
            theme,
            quality,
            store,
            keyword: keyword.id,
        });
        setTickets(prev => prev.map(t => {
            if (t.id === ticket.id) {
                return res.data;
            }
            return t;
        }));
        setLoading(false);
        hideModal();
    }

    const create = async() => {
        if (activity === null || theme === null) {
            return;
        }

        setLoading(true);
        const res = await updateDataAPI('POST', 'product-dev/gen-data-tickets/?additionalFields=dataSetsData,ideaData', {
            activity,
            theme,
            quality,
            store,
            keyword: keyword.id,
        });
        setTickets(prev => prev.concat(res.data));
        setLoading(false);
        hideModal();
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            contentLabel="Create Gen Data Modal"
            className="create-gen-data-ticket-modal"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {ticket ? (
                        <div>Edit Ticket</div>
                    ) : (
                        <div>Create New Ticket</div>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Activity</Form.Label>
                        <FilterByActivity filteringActivity={activity} setFilteringActivity={setActivity} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Theme</Form.Label>
                        <FilterByTheme filteringTheme={theme} setFilteringTheme={setTheme} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Quality</Form.Label>
                        <Form.Select value={quality} onChange={e => setQuality(e.target.value)}>
                            <option value={null}></option>
                            <option value="good">Good</option>
                            <option value="medium">Medium</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Store</Form.Label>
                        <Form.Select value={store} onChange={e => setStore(e.target.value)}>
                            <option value={null}></option>
                            {keyword.boomStores.map(s => (
                                <option key={s} value={s}>Store {s}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {ticket ? (
                    <Button onClick={save}>Save</Button>
                ) : (
                    activity && theme && quality && store && (
                        <Button onClick={create}>Create</Button>
                    )
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default GenDataTicketModal;