import { Container, Form, Table } from 'react-bootstrap';

import './styles.scss';
import { updateDataAPI } from '../../../../utils/query';
import { useSelector } from 'react-redux';

const StoreActivitiesTab = ({ store, setStore, setLoading }) => {
    const activities = useSelector(state => state.filters.activities);

    const toggleActivity = async (activity, quality) => {
        let boomActivities;
        if (store.boomActivities === null) {
            boomActivities = {
                [activity]: {
                    qualities: [quality]
                }
            };
        } else if (store.boomActivities[activity]) {
            if (store.boomActivities[activity].qualities === undefined) {
                boomActivities = {
                    ...store.boomActivities,
                    [activity]: {
                        qualities: [quality]
                    }
                };
            } else {
                let newQualities;
                if (store.boomActivities[activity].qualities.indexOf(quality) > -1) {
                    newQualities = store.boomActivities[activity].qualities.filter(q => q !== quality);
                } else {
                    newQualities = [...store.boomActivities[activity].qualities, quality];
                }
                boomActivities = {
                    ...store.boomActivities,
                    [activity]: {
                        qualities: newQualities
                    }
                };
            }
        } else {
            boomActivities = {
                ...store.boomActivities,
                [activity]: {
                    qualities: [quality]
                }
            };
        }
        setStore({ ...store, boomActivities });
        await save('boomActivities', boomActivities);
    }

    const toggleHasSeasonalProducts = async () => {
        const hasSeasonalProducts = !store.hasSeasonalProducts;
        setStore({ ...store, hasSeasonalProducts });
        await save('hasSeasonalProducts', hasSeasonalProducts);
    }

    const save = async (field, value) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'stores/' + store.id + '/', {
            [field]: value,
        });
        setLoading(false);
    }

    return (
        <Container className="store-activities-tab" fluid>
            <div>
                <Form.Check
                    type="checkbox"
                    label="Has Seasonal Products"
                    id="store-has-seasonal-products-checkbox"
                    checked={store.hasSeasonalProducts}
                    onChange={toggleHasSeasonalProducts}
                />
            </div>

            <Table striped bordered hover className="mt-5">
                <thead>
                    <tr>
                        <th>Activity</th>
                        <th>Qualities</th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map(activity => (
                        <tr key={activity['slug']}>
                            <td>{activity['label']}</td>
                            <td>
                                <Form.Check
                                    id={'activity-' + activity['slug'] + '-good-quality-checkbox'}
                                    type="checkbox"
                                    label="good quality"
                                    checked={store.boomActivities?.[activity['slug']]?.qualities && store.boomActivities?.[activity['slug']].qualities.indexOf('good') > -1}
                                    onChange={() => toggleActivity(activity['slug'], 'good')}
                                />
                                <Form.Check
                                    id={'activity-' + activity['slug'] + '-medium-quality-checkbox'}
                                    type="checkbox"
                                    label="medium quality"
                                    checked={store.boomActivities?.[activity['slug']]?.qualities && store.boomActivities?.[activity['slug']].qualities.indexOf('medium') > -1}
                                    onChange={() => toggleActivity(activity['slug'], 'medium')}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default StoreActivitiesTab;