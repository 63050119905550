
import { Form, Alert, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faClose, faRobot } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import IdeaModal from '../../pages/idea-list/idea-modal';
import Constant from '../../Constant';
import SalesHistoryModal from '../../components/sales/sales-history-modal';

const SearchIdeaInput = ({ store = null }) => {
    const [searchKeyword, setSearchKeyword] = useState();
    const [result, setResult] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [searching, setSearching] = useState(false);
    const [filteredTaskStep, setFilteredTaskStep] = useState('all');
    const [filteredMarketplace, setFilteredMarketplace] = useState('tpt');
    const [filteredInfoWithSales, setFilteredInfoWithSales] = useState('no');
    const [allTaskSteps, setAllTaskSteps] = useState([]);
    const [editingIdea, setEditingIdea] = useState(null);
    const inputRef = useRef(null);
    const [userRoles, setUserRoles] = useState([]);
    const [ideaInSalesHistoryModal, setIdeaInSalesHistoryModal] = useState(null);

    useEffect(() => {
        inputRef.current.focus();
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        const fetchAllTaskSteps = async () => {
            const res = await getDataFromAPI('product-dev/task-flow-steps/');
            setAllTaskSteps(res.data);
        }

        fetchAllTaskSteps();
    }, []);

    useEffect(() => {
        const delayInputTitleTimeoutID = setTimeout(async() => {
            if (searchKeyword) {
                setSearching(true);
                let url = 'resources/search-ideas/?keyword=' + searchKeyword + '&infoWithSales=' + filteredInfoWithSales;
                if (store) {
                    url += '&store=' + store;
                }
                if (filteredTaskStep !== 'all') {
                    url += '&step=' + filteredTaskStep;
                }
                if (filteredMarketplace !== 'all') {
                    url += '&marketplace=' + filteredMarketplace;
                }
                const res = await getDataFromAPI(url + '&additionalFields=storeData');
                setResult(res.data);
                setNoResults(res.data.length === 0);
                setSearching(false);
            } else {
                setResult([]);
            }
        }, 500);
        return () => clearTimeout(delayInputTitleTimeoutID);
    }, [searchKeyword, filteredTaskStep, filteredInfoWithSales, 500]);

    return (
        <div className="search-idea-input-form">
            <div className="filters">
                <Form onSubmit={e => e.preventDefault()}>
                    <Form.Control
                        ref={inputRef}
                        placeholder="Search Idea"
                        value={searchKeyword}
                        onChange={e => setSearchKeyword(e.target.value)}
                        />
                    <FontAwesomeIcon className="close-icon" icon={faClose} onClick={() => setSearchKeyword('')} />
                </Form>
                <Form.Select value={filteredTaskStep} onChange={e => setFilteredTaskStep(e.target.value)}>
                    <option value="all">All Steps</option>
                    {allTaskSteps.map((taskStep, index) => (
                        <option key={index} value={taskStep.id}>{taskStep.categoryName}. {taskStep.label}</option>
                    ))}
                </Form.Select>
                <Form.Select value={filteredMarketplace} onChange={e => setFilteredMarketplace(e.target.value)}>
                    <option value="all">All Marketplaces</option>
                    <option value="tpt">tpt</option>
                    <option value="boom">boom</option>
                </Form.Select>
                {userRoles.indexOf('admin') > -1 && (
                    <Form.Select value={filteredInfoWithSales} onChange={e => setFilteredInfoWithSales(e.target.value)}>
                        <option value="yes">yes</option>
                        <option value="no">no</option>
                    </Form.Select>
                )}
            </div>
            {noResults && (
                <Alert variant="danger" className="mt-3">Can not find any idea with this keyword "{searchKeyword}"</Alert>
            )}
            {result.length > 0 && (
                <Table striped bordered hover>
                    <tbody>
                        {result.map((idea, index) => (
                            <tr key={idea['id']}>
                                <td>{index + 1}</td>
                                <td><img src={idea['thumbnail']} /></td>
                                <td>Store {idea['store']}</td>
                                <td>
                                    <div onClick={() => setEditingIdea(idea)} className="idea-title">
                                        {idea['customID']}. {(idea['title'] ? idea['title'] : idea['name'])}
                                    </div>

                                    <div className="idea-info">${idea['price']}</div>
                                    <div className="idea-info">{idea['nbPages']} pages</div>
                                </td>
                                <td>{idea['task']?.stepLabel}</td>
                                <td>
                                    {userRoles.indexOf('admin') > -1 && filteredInfoWithSales === 'yes' && 'totalSales' in idea && (
                                        <div className="total-sales" onClick={() => setIdeaInSalesHistoryModal(idea)}>{idea['totalSales']} sales</div>
                                    )}
                                </td>
                                <td>
                                    <div className="link-icons">
                                        <FontAwesomeIcon icon={faRobot} onClick={() => window.open(window.location.origin + '/content-studio/?customID=' + idea.customID, '_blank', 'noreferrer')} />
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} onClick={() => window.open(window.location.origin + '/ideas?selectedStoreID=' + idea['store'] + '&searchKeyword=' + idea['customID'], '_blank', 'noreferrer')} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {ideaInSalesHistoryModal !== null && (
                <SalesHistoryModal
                    idea={ideaInSalesHistoryModal}
                    hideModal={() => setIdeaInSalesHistoryModal(null)}
                />
            )}

            {editingIdea !== null && (
                <IdeaModal
                    idea={editingIdea}
                    closeModal={() => setEditingIdea(null)}
                />
            )}
            {searching && <div>Searching...</div>}
        </div>
    )
};

export default SearchIdeaInput;